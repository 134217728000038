import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useApp } from "../../../AppProvider";
import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CopyIcon from "@material-ui/icons/FileCopy";
import {
  Accordion,
  AccordionDetails,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  TextField,
  Link,
  Button,
  AccordionSummary,
  Typography as MuiTypography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

import Panel from "../../../components/panels/Panel";
import Loader from "../../../components/Loader";
import { copyToClipboard, dateFormatter } from "../../../utils";
import useFetchData from "../../../hooks/useFetchData";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function CertificateHolders() {
  const { doToast } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const [selectedRow, setSelectedRow] = useState(null);

  const defaultRowValues = {
    holder_ndx: null,
    holder_desc: null,
    remark: null,
    archived: false,
  };

  const handleUpdateSelectedRow = (name, value) => {
    setSelectedRow((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const [listHolders] = useFetchData("list-holders", [], true);

  const { data } = useQuery(
    ["list-holder"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-holder`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const [filteredData, setFilteredData] = useState(null);
  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  const editTableColumns = [
    {
      title: "Certificate Holder",
      field: "holder_desc",
    },
    {
      title: "Notes",
      field: "remark",
    },
    {
      title: "Archived",
      field: "archived",
      type: "boolean",
    },
  ];

  const handleSubmit = () => {
    if (selectedRow.holder_ndx) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  const handleAdd = () => {
    return (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data: addedEntry } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/list-holder`,
          selectedRow,
          { headers }
        );
        //update the material table
        setFilteredData((prevState) => {
          let data = [...prevState];
          data.unshift(addedEntry);
          return data;
        });
        doToast("success", "New entry was saved to the database");
        //clear the currentRow and removes the form
        setSelectedRow(null);
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const archivedOptions = [
    {
      value: true,
      option: "Yes",
    },
    {
      value: false,
      option: "No",
    },
  ];

  const handleUpdate = () => {
    return (async () => {
      try {
        if (selectedRow) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.put(
            `${process.env.REACT_APP_ENDPOINT}/api/list-holder/${selectedRow.holder_ndx}`,
            selectedRow,
            { headers }
          );
          //update the material table
          setFilteredData((prevState) => {
            const data = [...prevState];
            data[
              data.findIndex(
                (item) => item.holder_ndx === selectedRow.holder_ndx
              )
            ] = selectedRow;
            return data;
          });
          doToast("success", "New data was updated to the database");
          //clear the currentRow and removes the form
          setSelectedRow(null);
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.delete(
            `${process.env.REACT_APP_ENDPOINT}/api/list-holder/${oldData.holder_ndx}`,
            { headers }
          );
          //update the material table
          setFilteredData((prevState) => {
            const data = [...prevState];
            const index = oldData.tableData.id;
            data.splice(index, 1);
            return data;
          });
          doToast("success", "This entry was deleted from the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const disableSubmit = !selectedRow?.holder_desc;

  return (
    <React.Fragment>
      <Helmet title="Certificate Holders" />
      <Typography variant="h3" gutterBottom display="inline">
        Certificate Holders
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Certificate Holders</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Certificate Holders
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  {filteredData ? (
                    <MaterialTable
                      id="Certificate Holders"
                      title={`Certificate Holders ${dateFormatter(
                        new Date(),
                        "MM/DD/YYYY, h:mm A"
                      )}`}
                      columns={editTableColumns}
                      data={filteredData}
                      editable={{
                        onRowDelete: handleDelete,
                        isDeletable: (rowData) =>
                          !listHolders.find(
                            (item) => item.holder_ndx === rowData?.holder_ndx
                          )?.assoc_certificate_ndx?.length,
                      }}
                      localization={{
                        toolbar: { searchPlaceholder: "Search Holders" },
                      }}
                      components={{
                        Container: (props) => <div {...props} />,
                      }}
                      actions={[
                        {
                          icon: CopyIcon,
                          tooltip: "Copy Data",
                          isFreeAction: true,
                          onClick: () => {
                            try {
                              copyToClipboard(
                                filteredData,
                                editTableColumns,
                                () =>
                                  doToast(
                                    "success",
                                    "Data was copied to your clipboard."
                                  )
                              );
                            } catch (error) {
                              const message =
                                error?.message ?? "Something went wrong";
                              doToast("error", message);
                            }
                          },
                        },
                        () => ({
                          icon: "edit",
                          tooltip: "Edit",
                          onClick: (event, rowData) => {
                            setSelectedRow(rowData);
                          },
                        }),
                        {
                          icon: "add_box",
                          tooltip: "Add",
                          isFreeAction: true,
                          onClick: () => {
                            setSelectedRow(defaultRowValues);
                          },
                        },
                      ]}
                      options={{
                        emptyRowsWhenPaging: false,
                        showTitle: false,
                        columnsButton: true,
                        exportButton: true,
                        exportAllData: true,
                        addRowPosition: "first",
                        pageSize: 200,
                        pageSizeOptions: [10, 30, 50, 100, 200],
                        padding: "dense",
                        searchFieldAlignment: "left",
                        maxBodyHeight: "400px",
                      }}
                    />
                  ) : (
                    <Loader />
                  )}
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      {selectedRow && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="table-content"
                id="table-header"
              >
                <Typography variant="h4" ml={2}>
                  {!selectedRow.holder_ndx
                    ? "New Certificate Holder"
                    : `Editing Certificate Holder "${selectedRow.holder_desc}"`}
                </Typography>
              </AccordionSummary>
              <Panel>
                <AccordionDetails>
                  <Grid container spacing={4}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        required
                        error={!selectedRow.holder_desc}
                        helperText={
                          !selectedRow.holder_desc ? "*Required field" : ""
                        }
                        variant="outlined"
                        label="Certificate Holder"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("holder_desc", e.target.value)
                        }
                        value={selectedRow.holder_desc || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        disabled
                        variant="outlined"
                        label="Associated Certificates"
                        style={{ width: "100%" }}
                        value={
                          listHolders.find(
                            (item) =>
                              item.holder_ndx === selectedRow?.holder_ndx
                          )?.assoc_certificate_no || "<No Associations>"
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={8}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Notes"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("remark", e.target.value)
                        }
                        value={selectedRow.remark || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={4}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        required
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={![false, true].includes(selectedRow.archived)}
                      >
                        <InputLabel id="archived">Archived?</InputLabel>
                        <Select
                          required
                          labelId="archived-label"
                          id="archived"
                          label="Archived?"
                          value={
                            [false, true].includes(selectedRow.archived)
                              ? selectedRow.archived
                              : ""
                          }
                          onChange={(e) =>
                            handleUpdateSelectedRow("archived", e.target.value)
                          }
                        >
                          {archivedOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.option}
                            </MenuItem>
                          ))}
                        </Select>
                        {![false, true].includes(selectedRow.archived) && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{
                        position: "relative",
                      }}
                    />
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Button
                        style={{ width: "100%", height: "44px" }}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => setSelectedRow(null)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Button
                        style={{ width: "100%", height: "44px" }}
                        type="submit"
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={disableSubmit}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Panel>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default CertificateHolders;
