import React, { useState } from "react";
import { useQuery } from "react-query";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const DevelopmentsOverview = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isFetching, error } = useQuery(
    ["report-development-details"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/report-development-details`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const tabColumns = [
    {
      title: "Development",
      field: "development_desc",
    },
    {
      title: "Associated Certificates",
      field: "assoc_certificate_no",
      render: (rowData) => rowData.assoc_certificate_no?.join(", ") || "",
    },
    {
      title: "Related Documents",
      field: "documents",
    },
    {
      title: "Archived?",
      field: "archived",
      render: (rowData) =>
        rowData.archived ? "Yes" : rowData.archived === false ? "No" : "",
    },
  ];

  const [showArchived, setShowArchived] = useState(false);

  const handleShowArchived = () => {
    setShowArchived((prevState) => {
      return !prevState;
    });
  };

  const filterData = (data) => {
    if (showArchived) {
      return data;
    } else return data?.filter((d) => !d.archived);
  };

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <Helmet title="Developments Overview" />
      <Typography variant="h3" gutterBottom display="inline">
        Developments
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Developments</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Panel>
        <TableWrapper>
          <Table
            pageSize={200}
            options={{ filtering: false }}
            label="Developments Overview"
            isLoading={isFetching}
            columns={tabColumns}
            data={filterData(data)}
            height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
            actions={[
              {
                icon: showArchived ? "toggle_on" : "toggle_off",
                iconProps: {
                  style: {
                    color: showArchived ? "#4CAF50" : "currentcolor",
                  },
                },
                tooltip: showArchived
                  ? "Toggle to hide archived records"
                  : "Toggle to show archived records",
                isFreeAction: true,
                onClick: handleShowArchived,
              },
            ]}
          />
        </TableWrapper>
      </Panel>
    </>
  );
};

export default DevelopmentsOverview;
