import React from "react";
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Upload from "../../dataAccess/uploads/Upload";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));
const SingleLotCertificateUpload = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const steps = [
    {
      label: "Download template and instructions",
      description: (
        <>
          Use this{" "}
          <Link
            rel="noreferrer noopener"
            component="a"
            target="_blank"
            href="https://docs.google.com/spreadsheets/d/11Ftf6ulEJJY7r7f6t8p52HDfsaBLRXVYYqmbutF_X2M/edit#gid=0"
          >
            <strong>TEMPLATE </strong>
          </Link>{" "}
          sheet to set up your data. Reference the{" "}
          <Link
            rel="noreferrer noopener"
            target="_blank"
            component="a"
            href="https://docs.google.com/spreadsheets/d/11Ftf6ulEJJY7r7f6t8p52HDfsaBLRXVYYqmbutF_X2M/edit#gid=1535899441"
          >
            <strong>INSTRUCTIONS </strong>
          </Link>{" "}
          sheet in the template for more details.
        </>
      ),
      content: "",
    },
    {
      label: "Construct data file",
      description: (
        <>
          Using the template and instructions as provided above, create the data
          file and save it to your computer as a type CSV file. Double check
          that the headers and file type match as described in the instructions.
        </>
      ),
      content: (
        <Alert
          severity="info"
          style={{ marginTop: "8px", marginBottom: "20px" }}
        >
          <AlertTitle>
            <strong>**Double-check format.**</strong>
          </AlertTitle>
          — Uploaded file must be of type .csv (comma delimited)
          <br />— CSV file must have a single header row and these nine fields,
          in this order:
          <br />
          <strong>
            Certificate Name | Certificate Holder | Notes | Subdivision Name |
            Lot | Block | Address | Lot Area SqFt | Amount AF
          </strong>
        </Alert>
      ),
    },
    {
      label: "Attach data file",
      description: `Click the "Select File" button and navigate to and select your newly saved CSV file. Click the "Upload File" button to add the data and start the upload process.`,
      content: (
        <>
          <Upload
            acceptFileTypes=".xlsx, .xls, .csv"
            endpoint="single-lot-certificate-upload-set-up-attachments"
            documentTagsEndpoint="list-document-tags/single-lot-certificate-upload"
            awsFolder="csv"
            defaultDocumentTagNdx={19}
            disableDocumentTag={true}
          />
        </>
      ),
    },
    {
      label: "View uploaded records",
      description: (
        <>
          Once your file has been attached, navigate to the{" "}
          <Link
            component={NavLink}
            rel="noreferrer noopener"
            target="_blank"
            exact
            to="/accounting/log-entry"
          >
            <strong>Log Entry Page </strong>
          </Link>
          and select your certificate to see the uploaded records.
        </>
      ),
      content: "",
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Helmet title="Single Lot Certificate Upload" />
      <Typography variant="h3" gutterBottom display="inline">
        Single Lot Certificate Upload
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Single Lot Certificate Upload</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Panel>
        <TableWrapper>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent>
                    <Typography>{step.description}</Typography>
                    <Box mt={3} mb={3}>
                      {step.content}
                    </Box>

                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                        >
                          {activeStep === steps.length - 1 ? "Finish" : "Next"}
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} className={classes.resetContainer}>
                <Typography>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} className={classes.button}>
                  Reset
                </Button>
              </Paper>
            )}
          </div>
        </TableWrapper>
      </Panel>
    </>
  );
};

export default SingleLotCertificateUpload;
