import React from "react";
import { Button, Grid } from "@material-ui/core";

const EditorActionButtons = React.memo(
  ({ setSelectedItem, handleSubmit, disableSubmit }) => {
    return (
      <>
        <Grid item xs={8}>
          &nbsp;
        </Grid>
        <Grid item xs={2}>
          <Button
            style={{ width: "100%", height: "44px" }}
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => setSelectedItem(null)}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            style={{ width: "100%", height: "44px" }}
            type="submit"
            size="small"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={disableSubmit}
          >
            Save
          </Button>
        </Grid>
      </>
    );
  }
);

export default EditorActionButtons;
