import React from "react";
import { useQuery } from "react-query";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const CommonAreaIrrigationTrackingReport = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isFetching, error } = useQuery(
    ["report-development-cai"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/report-development-cai`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (error) return "An error has occurred: " + error.message;

  const tabColumns = [
    {
      title: "Development",
      field: "development_desc",
    },
    {
      title: "Initial Payment Due!",
      field: "flag_initial_payment_due",
      type: "date",
    },
    {
      title: "Final Payment Due Soon!",
      field: "flag_final_payment_due",
      type: "date",
    },
    {
      title: "% Allocated",
      field: "credit_used_pct",
    },
    {
      title: "% Remaining",
      field: "credit_remaining_pct",
    },
    {
      title: "Total Development Credit (AF)",
      field: "credit_amount_acft",
    },
    {
      title: "Total Development Allocated (AF)",
      field: "debit_amount_acft",
    },
    {
      title: "Notes",
      field: "cai_payment_remark",
    },
    {
      title: "Associated Certificates",
      field: "certificate_no",
    },
  ];

  return (
    <>
      <Helmet title="Common Area Irrigation Tracking" />
      <Typography variant="h3" gutterBottom display="inline">
        Common Area Irrigation Tracking Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Common Area Irrigation Tracking Report</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Panel>
        <TableWrapper>
          <Table
            pageSize={200}
            options={{ filtering: false }}
            label="Common Area Irrigation Tracking"
            isLoading={isFetching}
            columns={tabColumns}
            data={data}
            height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
          />
        </TableWrapper>
      </Panel>
    </>
  );
};

export default CommonAreaIrrigationTrackingReport;
