import React, { useCallback, useMemo } from "react";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  Accordion,
  AccordionDetails,
  Grid as MuiGrid,
  AccordionSummary,
  Typography as MuiTypography,
  Paper,
} from "@material-ui/core";

import Panel from "../../../../components/panels/Panel";

import { useSubmitActions } from "./hooks/useSubmitActions";
import { useCertificateForm } from "./hooks/useCertificateForm";
import Loader from "../../../../components/Loader";
import ExistingHolderAndDevelopment from "./sections/ExistingHolderAndDevelopment";
import NewHolder from "./sections/NewHolder";
import NewDevelopment from "./sections/NewDevelopment";
import CertificateDetails from "./sections/CertificateDetails";
import EditorActionButtons from "./sections/EditorActionButtons";
import UploadSection from "./sections/UploadSection";

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const CertificateForm = React.memo(
  ({
    selectedItem,
    setSelectedItem,
    refetchCertificatesAndLookups,
    refetchTable,
    inputLookups,
  }) => {
    const {
      isLoading,
      error,
      handleUpdateEditorState,
      certificateTypes,
      editorState,
    } = useCertificateForm(selectedItem);

    const { handleAdd, handleUpdate } = useSubmitActions();

    const handleSubmit = useCallback(async () => {
      try {
        if (editorState.certificate_ndx) {
          await handleUpdate(editorState, refetchTable);
        } else {
          await handleAdd(editorState, refetchCertificatesAndLookups);
        }
        setSelectedItem(null);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }, [
      editorState,
      handleUpdate,
      handleAdd,
      refetchTable,
      refetchCertificatesAndLookups,
      setSelectedItem,
    ]);

    const disableSubmit = useMemo(
      () =>
        !editorState.certificate_no ||
        (!editorState?.holder_ndx && !editorState?.tmp_cert_holder_desc) ||
        (!editorState?.development_ndx && !editorState?.tmp_development_desc) ||
        !editorState?.certificate_type_ndx,
      [
        editorState.certificate_no,
        editorState?.certificate_type_ndx,
        editorState?.development_ndx,
        editorState?.holder_ndx,
        editorState?.tmp_cert_holder_desc,
        editorState?.tmp_development_desc,
      ]
    );
    if (error)
      return "An error has occurred loading the certificate: " + error.message;
    return (
      <>
        {isLoading ? (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ) : selectedItem || selectedItem === 0 ? (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="table-content"
                  id="table-header"
                >
                  <Typography variant="h4" ml={2}>
                    {!editorState.certificate_ndx
                      ? "New Certificate"
                      : `Editing Certificate "${editorState.certificate_no}"`}
                  </Typography>
                </AccordionSummary>
                <Panel>
                  <AccordionDetails>
                    <Grid container spacing={4}>
                      <ExistingHolderAndDevelopment
                        certNdx={editorState.certificate_ndx}
                        holderNdx={editorState.holder_ndx}
                        developmentNdx={editorState.development_ndx}
                        certHolderAddNew={editorState.cert_holder_add_new}
                        developmentAddNew={editorState.development_add_new}
                        tmpCertHolderDesc={editorState.tmp_cert_holder_desc}
                        tmpDevelopmentDesc={editorState.tmp_development_desc}
                        handleUpdateEditorState={handleUpdateEditorState}
                        inputLookups={inputLookups}
                      />
                      {editorState.cert_holder_add_new && (
                        <NewHolder
                          tmpCertHolderDesc={editorState.tmp_cert_holder_desc}
                          certHolderAddNew={editorState.cert_holder_add_new}
                          tmpCertHolderRemark={
                            editorState.tmp_cert_holder_remark
                          }
                          handleUpdateEditorState={handleUpdateEditorState}
                        />
                      )}
                      {editorState.development_add_new && (
                        <NewDevelopment
                          tmpDevelopmentDesc={editorState.tmp_development_desc}
                          developmentAddNew={editorState.development_add_new}
                          tmpDevelopmentRemark={
                            editorState.tmp_development_remark
                          }
                          tmpDevelopmentCaiInitial50PctPaid={
                            editorState.tmp_development_cai_initial_50pct_paid
                          }
                          tmpDevelopmentCaiFinal50PctPaid={
                            editorState.tmp_development_cai_final_50pct_paid
                          }
                          tmpDevelopmentCaiPaymentRemark={
                            editorState.tmp_development_cai_payment_remark
                          }
                          handleUpdateEditorState={handleUpdateEditorState}
                        />
                      )}
                      <CertificateDetails
                        certificateNdx={editorState.certificate_ndx}
                        certificateNo={editorState.certificate_no}
                        holderHasCopy={editorState.holder_has_copy}
                        archived={editorState.archived}
                        certificateDate={editorState.certificate_date}
                        remark={editorState.remark}
                        certificateTypeNdx={editorState.certificate_type_ndx}
                        pudNotes={editorState.pud_notes}
                        assocCertificateNo={editorState.assoc_certificate_no}
                        archivedNotes={editorState.archived_notes}
                        paymentFileName={editorState.payment_file_name}
                        paymentRemark={editorState.payment_remark}
                        certificateTypes={certificateTypes}
                        certHolderAddNew={editorState.cert_holder_add_new}
                        developmentAddNew={editorState.development_add_new}
                        handleUpdateEditorState={handleUpdateEditorState}
                      />
                      <EditorActionButtons
                        setSelectedItem={setSelectedItem}
                        handleSubmit={handleSubmit}
                        disableSubmit={disableSubmit}
                      />
                      {editorState.certificate_ndx && (
                        <UploadSection
                          certificateNdx={editorState.certificate_ndx}
                        />
                      )}
                    </Grid>
                  </AccordionDetails>
                </Panel>
              </Accordion>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </>
    );
  }
);

export default CertificateForm;
