import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import styled, { ThemeProvider } from "styled-components/macro";
import { STARTING_LOCATION } from "../../../constants";
import debounce from "lodash.debounce";
import { lineColors } from "../../../utils";
import ReactDOM from "react-dom";
import { jssPreset, StylesProvider } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import createTheme from "../../../theme";
import Popup from "../../publicMap/popup";
import { create } from "jss";
import { useSelector } from "react-redux";
import ResetZoomControl from "../../../components/map/ResetZoomControl";
import ToggleBasemapControl from "../../../components/map/ToggleBasemapControl";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const DashboardMap = ({
  data,
  selectedLocations,
  error,
  isLoading,
  flyToCoordinates,
}) => {
  const theme = useSelector((state) => state.themeReducer);
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [map, setMap] = useState();

  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "400px",
      offset: 15,
      focusAfterOpen: false,
    })
  );
  const mapContainer = useRef(null); // create a reference to the map container

  const DUMMY_BASEMAP_LAYERS = [
    { url: "streets-v11", icon: "commute" },
    { url: "outdoors-v11", icon: "park" },
    { url: "satellite-streets-v11", icon: "satellite_alt" },
  ];

  const locationsLayer = {
    id: "locations",
    type: "circle",
    source: "locations",
    paint: {
      "circle-stroke-width": [
        "case",
        ["in", ["get", "index"], ["literal", selectedLocations]],
        5,
        1,
      ],
      "circle-stroke-color": [
        "case",
        ["in", ["get", "index"], ["literal", selectedLocations]],
        lineColors.yellow,
        "black",
      ],
      "circle-radius": 7,
      "circle-color": "#1e8dd2",
    },
    lreProperties: {
      popup: {
        titleField: "description",
        excludeFields: ["index", "description"],
      },
    },
  };

  const locationsLabelsLayer = {
    id: "locations-labels",
    type: "symbol",
    source: "locations",
    minzoom: 11.5,
    layout: {
      "text-field": ["get", "developmentDescription"],
      "text-size": 14,
      "text-offset": [0, -1.7],
      "text-font": ["literal", ["Roboto Black", "Arial Unicode MS Bold"]],
      visibility: "visible",
    },
    paint: {
      "text-color": "rgb(49,49,49)",
      "text-halo-color": "rgba(255,255,255,1)",
      "text-halo-width": 3,
    },
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/" + DUMMY_BASEMAP_LAYERS[0].url,
      center: STARTING_LOCATION,
      zoom: 11.5,
    });

    map.addControl(new mapboxgl.NavigationControl(), "top-left");
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      }),
      "top-left"
    );
    map.addControl(new mapboxgl.FullscreenControl());
    // Add locate control to the map.
    map.addControl(new ResetZoomControl(), "top-left");

    DUMMY_BASEMAP_LAYERS.forEach((layer) => {
      return map.addControl(new ToggleBasemapControl(layer.url, layer.icon));
    });

    map.on("load", () => {
      setMapIsLoaded(true);
      setMap(map);
    });
  }, []); // eslint-disable-line

  //resizes map when mapContainerRef dimensions changes (sidebar toggle)
  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
      resizer.observe(mapContainer.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map]);

  useEffect(() => {
    if (map && flyToCoordinates) {
      map.flyTo({
        center: flyToCoordinates,
        zoom: 15,
        padding: { bottom: 0 },
      });
    }
  }, [flyToCoordinates, map]);

  useEffect(() => {
    if (mapIsLoaded && data?.length > 0 && typeof map != "undefined") {
      if (!map.getSource("locations")) {
        map.addSource("locations", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: data.map((location) => {
              return {
                id: location.certificate_ndx,
                type: "Feature",
                properties: {
                  index: location.certificate_ndx,
                  certificateNumber: location.certificate_no,
                  holderDescription: location.holder_desc,
                  developmentDescription: location.development_desc,
                  totalCredits: location.total_credits_acft,
                  currentRemaining: location.current_remaining_acft,
                  lastEntryDate: location.last_entry_date,
                  status: location.status,
                },
                geometry: {
                  type: location.location_geometry.type,
                  coordinates: location.location_geometry.coordinates,
                },
              };
            }),
          },
        });
        // Add a layer showing the places.
        map.addLayer(locationsLayer);
        map.addLayer(locationsLabelsLayer);

        map.on("click", "locations", (e) => {
          map.fire("closeAllPopups");

          const features = map.queryRenderedFeatures(e.point);
          const myFeatures = features.filter(
            (feature) => feature.source === "locations"
          );
          const coordinates = [e.lngLat.lng, e.lngLat.lat];

          const popupNode = document.createElement("div");
          ReactDOM.render(
            //MJB adding style providers to the popup
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                <ThemeProvider theme={createTheme(theme.currentTheme)}>
                  <Popup
                    layers={[locationsLayer]}
                    features={myFeatures}
                    height="160px"
                    width="252px"
                    size="small"
                  />
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>,
            popupNode
          );
          popUpRef.current
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map);
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on("mouseenter", "locations", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        // Change it back to a pointer when it leaves.
        map.on("mouseleave", "locations", () => {
          map.getCanvas().style.cursor = "";
        });
      }
    }
  }, [isLoading, mapIsLoaded, map, data]); //eslint-disable-line

  //filters the table based on the selected radioValues filters
  useEffect(() => {
    if (map !== undefined && map.getLayer("locations")) {
      map.setFilter("locations", [
        "in",
        ["get", "index"],
        ["literal", data.map((item) => item.certificate_ndx)],
      ]);
    }
  }, [selectedLocations]); // eslint-disable-line

  useEffect(() => {
    if (map !== undefined && map.getLayer("locations")) {
      map.setPaintProperty("locations", "circle-stroke-width", [
        "case",
        ["in", ["get", "index"], ["literal", selectedLocations]],
        5,
        1,
      ]);
      map.setPaintProperty("locations", "circle-stroke-color", [
        "case",
        ["in", ["get", "index"], ["literal", selectedLocations]],
        lineColors.yellow,
        "black",
      ]);
    }
  }, [selectedLocations, data]); //eslint-disable-line

  if (error) return "An error has occurred: " + error.message;

  return (
    <Root>
      <MapContainer ref={mapContainer} />
    </Root>
  );
};

export default DashboardMap;
