import React from "react";
import { dateFormatterUi } from "../../../utils";
import { useQuery } from "react-query";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { getLookupForDataSortedByField } from "../../../utils";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const PopupUl = styled.ul`
  //list-style-type: none;
  margin: 0 !important;
  padding: 3px 0;
`;

//388px
const SingleLotsOverviewReport = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isFetching, error } = useQuery(
    ["report-certificate-details"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/report-certificate-details-single-lots`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (error) return "An error has occurred: " + error.message;
  const tabColumns = [
    {
      title: "Development",
      field: "development_desc",
      lookup: getLookupForDataSortedByField(data, "development_desc"),
    },
    {
      title: "Holder",
      field: "holder_desc",
      lookup: getLookupForDataSortedByField(data, "holder_desc"),
    },
    {
      title: "Cert Date",
      field: "certificate_date",
      type: "date",
      render: (rowData) => {
        return dateFormatterUi(rowData.certificate_date, "MM/dd/yyyy");
      },
      customFilterAndSearch: (filterValue, rowData) => {
        // corrects offset caused by the MUI date picker filter
        const rowDate = rowData.certificate_date;
        const filterDate = filterValue.toISOString().split("T")[0];

        return rowDate === filterDate;
      },
    },
    {
      title: "Certificate No.",
      field: "certificate_no",
    },
    {
      title: "Holder Has Copy?",
      field: "holder_has_copy",
      type: "boolean",
    },
    {
      title: "Certificate Docs",
      field: "documents_certificates",
      render: (rowData) => {
        return (
          <PopupUl>
            {rowData?.documents_certificates?.split(",").map((item) => (
              <li key={item}>{parse(item)}</li>
            ))}
          </PopupUl>
        );
      },
    },
    {
      title: "Development Docs",
      field: "documents_developments",
      render: (rowData) => {
        return (
          <PopupUl>
            {rowData?.documents_developments?.split(",").map((item) => (
              <li key={item}>{parse(item)}</li>
            ))}
          </PopupUl>
        );
      },
    },
  ];

  return (
    <>
      <Helmet title="Single Lots Overview" />
      <Typography variant="h3" gutterBottom display="inline">
        Single Lots Overview Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Single Lots Overview</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Panel>
        <TableWrapper>
          <Table
            pageSize={200}
            options={{
              filtering: true,
            }}
            label="Single Lots Overview Overview"
            isLoading={isFetching}
            columns={tabColumns}
            data={data}
            height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
          />
        </TableWrapper>
      </Panel>
    </>
  );
};

export default SingleLotsOverviewReport;
