export const DEFAULT_ROW_STATE = {
  cert_holder_add_new: false,
  certificate_ndx: null,
  certificate_no: null,
  holder_ndx: null,
  development_ndx: null,
  holder_has_copy: null,
  archived: false,
  certificate_date: null,
  remark: null,
  certificate_type_ndx: null,
  pud_notes: null,
  assoc_certificate_no: null,
  archived_notes: null,
  payment_file_name: null,
  payment_remark: null,
  tmp_cert_holder_desc: null,
  tmp_cert_holder_remark: null,
  development_add_new: false,
  tmp_development_desc: null,
  tmp_development_remark: null,
  tmp_development_cai_initial_50pct_paid: null,
  tmp_development_cai_final_50pct_paid: null,
  tmp_development_cai_payment_remark: null,
};
export const YES_NO_OPTIONS = [
  {
    value: true,
    option: "Yes",
  },
  {
    value: false,
    option: "No",
  },
];
