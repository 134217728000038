import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { RulerControl } from "mapbox-gl-controls";
import styled, { ThemeProvider } from "styled-components/macro";
import { STARTING_LOCATION } from "../../constants";
import createTheme from "../../theme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import debounce from "lodash.debounce";
import { create } from "jss";
import { jssPreset, StylesProvider } from "@material-ui/core/styles";
import {
  DUMMY_BASEMAP_LAYERS,
  handleCopyCoords,
  locationsLabelsLayer,
  locationsLayer,
} from "../../utils/map";
import CoordinatesPopup from "./components/CoordinatesPopup";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { coordinatesGeocoder } from "../../pages/publicMap/hooks/useMap/mapUtils";
import LocationsControl from "./LocationsControl";
import ReactDOM from "react-dom";
import Popup from "../../pages/publicMap/popup";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Container = styled.div`
  height: 320px;
  width: 100%;
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Instructions = styled.div`
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  position: absolute;
  text-align: center;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
  padding: 5px 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  z-index: 1000;
  display: block;
`;

const Map = ({ filterValues, setFilterValues }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [map, setMap] = useState();
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [locationsVisible, setLocationsVisible] = useState(false);
  const coordinatesContainerRef = useRef(null);
  const instructionsRef = useRef(null);
  const longRef = useRef(null);
  const latRef = useRef(null);
  const eleRef = useRef(null);
  const mapContainerRef = useRef(null); // create a reference to the map container
  const theme = useSelector((state) => state.themeReducer);

  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "400px",
      offset: 15,
      focusAfterOpen: false,
    })
  );

  const { data } = useQuery(
    ["ui-map-and-certificate-summary"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-map-and-certificate-summary`,
          { headers }
        );

        //filters out any well that does not have geometry data
        const filterData = data.filter(
          (location) => location.location_geometry
        );
        return filterData;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  async function getElevation() {
    // Construct the API request.
    const query = await fetch(
      `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${longRef.current.innerHTML},${latRef.current.innerHTML}.json?layers=contour&limit=50&access_token=${mapboxgl.accessToken}`,
      { method: "GET" }
    );
    if (query.status !== 200) return;
    const data = await query.json();

    const allFeatures = data.features;

    const elevations = allFeatures.map((feature) => feature.properties.ele);

    eleRef.current.innerHTML = (Math.max(...elevations) * 3.28084).toFixed(5);
  }

  //create map and apply all controls
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/" + DUMMY_BASEMAP_LAYERS[0].url,
      center:
        !filterValues.lon || !filterValues.lat
          ? STARTING_LOCATION
          : [filterValues.lon, filterValues.lat],
      zoom: !filterValues.lon || !filterValues.lat ? 10 : 10.5,
    });

    //top left controls
    map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        localGeocoder: coordinatesGeocoder,
        zoom: 16,
        mapboxgl: mapboxgl,
        reverseGeocode: true,
        placeholder: "Address/Coords Search",
        limit: 3,
      }),
      "top-left"
    );
    map.addControl(new mapboxgl.FullscreenControl(), "top-left");

    //bottom left controls
    map.addControl(
      new mapboxgl.ScaleControl({ unit: "imperial" }),
      "bottom-left"
    );
    map.addControl(
      new RulerControl({
        units: "feet",
        labelFormat: (n) => `${n.toFixed(2)} ft`,
      }),
      "bottom-left"
    );

    map.on("load", () => {
      setMapIsLoaded(true);
      setMap(map);
    });
  }, []); // eslint-disable-line

  //resizes map when mapContainerRef dimensions changes (sidebar toggle)
  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
      resizer.observe(mapContainerRef.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map]);

  const [Marker, setMarker] = useState();

  useEffect(() => {
    if (mapIsLoaded && data?.length > 0 && typeof map != "undefined") {
      const marker = new mapboxgl.Marker({
        draggable: true,
        color: "red",
      })
        .setLngLat(
          !filterValues.lon || !filterValues.lat
            ? STARTING_LOCATION
            : [filterValues.lon, filterValues.lat]
        )
        .addTo(map);

      setMarker(marker);

      if (filterValues.lon && filterValues.lat) {
        const lngLat = marker.getLngLat();
        coordinatesContainerRef.current.style.display = "block";
        instructionsRef.current.innerHTML =
          "Drag and place marker to update coordinates and elevation fields";
        longRef.current.innerHTML = lngLat.lng;
        latRef.current.innerHTML = lngLat.lat;
        getElevation();
      }

      if (!map.getSource("locations")) {
        map.addSource("locations", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: data.map((location) => {
              return {
                type: "Feature",
                id: location.certificate_ndx,
                properties: {
                  ...location,
                },
                geometry: {
                  type: location.location_geometry.type,
                  coordinates: location.location_geometry.coordinates,
                },
              };
            }),
          },
        });
        if (!map.getLayer("locations")) {
          map.addLayer({
            ...locationsLayer,
            layout: {
              visibility: "none",
            },
          });
          //add labels for locations points
          map.addLayer({
            ...locationsLabelsLayer,
            layout: {
              visibility: "none",
            },
          });
        }
      }

      const onDragEnd = (marker) => {
        const lngLat = marker.getLngLat();
        coordinatesContainerRef.current.style.display = "block";
        instructionsRef.current.innerHTML =
          "Click coordinate or elevation to copy individual result to clipboard";
        latRef.current.innerHTML = lngLat.lat.toFixed(5);
        setFilterValues("lat", lngLat.lat.toFixed(5));
        longRef.current.innerHTML = lngLat.lng.toFixed(5);
        setFilterValues("lon", lngLat.lng.toFixed(5));

        getElevation();
      };

      marker.on("dragend", () => onDragEnd(marker));

      // //handles copying coordinates and measurements to the clipboard
      const copyableRefs = [longRef, latRef, eleRef];
      copyableRefs.forEach((ref) => {
        ref.current.addEventListener("click", (e) =>
          handleCopyCoords(e.target.textContent)
        );
      });

      map.on("click", (e) => {
        map.fire("closeAllPopups");

        const features = map.queryRenderedFeatures(e.point);

        const coordinates = [e.lngLat.lng, e.lngLat.lat];

        const popupLayerIds = ["locations"];

        const myFeatures = features.filter((feature) =>
          popupLayerIds.includes(feature?.layer?.id)
        );

        if (myFeatures.length > 0) {
          const popupNode = document.createElement("div");
          ReactDOM.render(
            //MJB adding style providers to the popup
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                <ThemeProvider theme={createTheme(theme.currentTheme)}>
                  <Popup
                    renderGraphButtons={false}
                    layers={[locationsLayer]}
                    features={myFeatures}
                    height="144px"
                    width="252px"
                    size="small"
                  />
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>,
            popupNode
          );
          popUpRef.current
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map);
        }
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      map.on("mouseenter", "locations", () => {
        map.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      map.on("mouseleave", "locations", () => {
        map.getCanvas().style.cursor = "";
      });
    }
  }, [mapIsLoaded, map, data]); //eslint-disable-line

  const locationsLayers = ["locations-labels", "locations"];
  useEffect(() => {
    if (
      map !== undefined &&
      map.getLayer("locations") &&
      map.getLayer("locations-labels")
    ) {
      if (!locationsVisible) {
        locationsLayers.forEach((layer) =>
          map.setLayoutProperty(layer, "visibility", "none")
        );
      } else {
        locationsLayers.forEach((layer) =>
          map.setLayoutProperty(layer, "visibility", "visible")
        );
      }
    }
  }, [locationsVisible]); // eslint-disable-line

  useEffect(() => {
    if (map && filterValues && Marker) {
      if (!filterValues.lat || !filterValues.lon) {
        latRef.current.innerHTML = "N/A";
        setFilterValues("lat", null);
        longRef.current.innerHTML = "N/A";
        setFilterValues("lon", null);
        eleRef.current.innerHTML = "N/A";
      } else {
        coordinatesContainerRef.current.style.display = "block";
        latRef.current.innerHTML = (+filterValues?.lat)?.toFixed(5);
        setFilterValues("lat", (+filterValues?.lat)?.toFixed(5));
        longRef.current.innerHTML = (+filterValues?.lon)?.toFixed(5);
        setFilterValues("lon", (+filterValues?.lon)?.toFixed(5));
        getElevation();
      }

      Marker.setLngLat(
        !filterValues.lon || !filterValues.lat
          ? STARTING_LOCATION
          : [filterValues.lon, filterValues.lat]
      );

      map.flyTo({
        center:
          !filterValues.lon || !filterValues.lat
            ? STARTING_LOCATION
            : [filterValues.lon, filterValues.lat],
        zoom: !filterValues.lon || !filterValues.lat ? 10 : 10.5,
        padding: { bottom: 0 },
      });
    }
  }, [map, filterValues.development_ndx]); //eslint-disable-line

  return (
    <>
      <Container>
        <MapContainer ref={mapContainerRef}>
          <LocationsControl
            open={locationsVisible}
            onToggle={() => setLocationsVisible(!locationsVisible)}
            top={10}
          />

          <CoordinatesPopup
            coordinatesContainerRef={coordinatesContainerRef}
            longRef={longRef}
            latRef={latRef}
            eleRef={eleRef}
            title="Red marker:"
          />

          <Instructions ref={instructionsRef}>
            Drag and place marker to generate coordinates and elevation fields
          </Instructions>
        </MapContainer>
      </Container>
    </>
  );
};

export default Map;
