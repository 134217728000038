import React, { useCallback, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  Accordion,
  AccordionDetails,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  AccordionSummary,
  Typography as MuiTypography,
} from "@material-ui/core";

import Panel from "../../../../components/panels/Panel";
import { dateFormatter } from "../../../../utils";
import { useCertificates } from "./hooks/useCertificates";
import { useSubmitActions } from "./hooks/useSubmitActions";
import CertificateForm from "./CertificateForm";
import parse from "html-react-parser";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

const MemoizedMaterialTable = React.memo(
  ({
    editTableColumns,
    data,
    onDelete,
    // listCertificates,
    isLoading,
    setSelectedItem,
  }) => {
    return (
      <MaterialTable
        id="Certificates"
        title={`Certificates ${dateFormatter(
          new Date(),
          "MM/DD/YYYY, h:mm A"
        )}`}
        columns={editTableColumns}
        data={data}
        editable={{
          onRowDelete: (rowData) => onDelete(rowData),
          // isDeletable: (rowData) =>
          //   // TODO get confirmation from kelly, has_log_records is not in the db
          //   !listCertificates.find(
          //     (item) => item.certificate_ndx === rowData?.certificate_ndx
          //   )?.has_log_records,
        }}
        localization={{
          toolbar: { searchPlaceholder: "Search Certificates" },
        }}
        actions={[
          () => ({
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, rowData) => {
              setSelectedItem(rowData.certificate_ndx);
            },
          }),
          {
            icon: "add_box",
            tooltip: "Add",
            isFreeAction: true,
            onClick: () => setSelectedItem(0),
          },
        ]}
        isLoading={isLoading}
        options={{
          emptyRowsWhenPaging: false,
          showTitle: false,
          columnsButton: true,
          exportButton: true,
          exportAllData: true,
          addRowPosition: "first",
          pageSize: 200,
          pageSizeOptions: [10, 30, 50, 100, 200],
          padding: "dense",
          searchFieldAlignment: "left",
          maxBodyHeight: "400px",
        }}
      />
    );
  }
);

const Certificates = () => {
  const {
    data,
    isLoading,
    error,
    refetchTable,
    refetchCertificatesAndLookups,
    setSelectedItem,
    selectedItem,
    tableLookups: {
      holderLookup,
      developmentLookup,
      attachmentsCertificatesLookup,
    },
    inputLookups,
  } = useCertificates();

  const { handleDelete } = useSubmitActions();

  const onDelete = useCallback(
    async (rowData) => {
      await handleDelete(rowData.certificate_ndx, refetchTable);
      setSelectedItem(null);
    },
    [handleDelete, refetchTable, setSelectedItem]
  );

  const editTableColumns = useMemo(
    () => [
      {
        title: "Certificate No.",
        field: "certificate_no",
      },
      {
        title: "Certificate Holder",
        field: "holder_ndx",
        lookup: holderLookup,
      },
      {
        title: "Development",
        field: "development_ndx",
        lookup: developmentLookup,
      },
      {
        title: "Holder Has Copy?",
        field: "holder_has_copy",
        type: "boolean",
        render: (rowData) =>
          rowData.holder_has_copy === true
            ? "Yes"
            : rowData.holder_has_copy === false
            ? "No"
            : "",
      },
      {
        title: "General Notes",
        field: "remark",
        cellStyle: {
          minWidth: 400,
          maxWidth: 400,
        },
      },
      {
        title: "Attachments",
        field: "certificate_ndx",
        lookup: attachmentsCertificatesLookup,
        render: (rowData) => {
          const htmlElements =
            attachmentsCertificatesLookup?.[rowData.certificate_ndx]?.split(
              ","
            ) || [];
          return htmlElements.map((item, i) => <li key={i}>{parse(item)}</li>);
        },
        cellStyle: {
          minWidth: 600,
          maxWidth: 600,
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attachmentsCertificatesLookup, developmentLookup, holderLookup, data]
  );

  if (error)
    return "An error has occurred loading the certificates: " + error.message;
  return (
    <>
      <Helmet title="Certificates" />
      <Typography variant="h3" gutterBottom display="inline">
        Certificates
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Certificates</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Certificates
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  <MemoizedMaterialTable
                    data={data}
                    setSelectedItem={setSelectedItem}
                    onDelete={onDelete}
                    editTableColumns={editTableColumns}
                    isLoading={isLoading}
                    listCertificates={inputLookups.listCertificates}
                  />
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>
      <CertificateForm
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        refetchCertificatesAndLookups={refetchCertificatesAndLookups}
        refetchTable={refetchTable}
        inputLookups={inputLookups}
      />
    </>
  );
};

export default Certificates;
