import React from "react";
import { useQuery } from "react-query";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import DataAdminTable from "../../../components/DataAdminTable";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const DocumentTags = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isFetching, error, refetch } = useQuery(
    ["list-document-tags/bulk-lot-upload"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-document-tags/bulk-lot-upload`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  if (error) return "An error has occurred: " + error.message;

  const columns = [
    {
      title: "Tag Text",
      field: "tag_text",
      validate: (rowData) =>
        !rowData.tag_text
          ? {
              isValid: false,
            }
          : true,
    },
    {
      title: "Tag Description",
      field: "tag_desc",
    },
  ];

  return (
    <>
      <Helmet title="Document Types" />
      <Typography variant="h3" gutterBottom display="inline">
        Document Types
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Document Types</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Panel>
        <TableWrapper>
          <DataAdminTable
            pageSize={10}
            isLoading={isFetching}
            label="Document Types"
            columns={columns}
            data={data}
            height="350px"
            handleRefresh={refetch}
            endpoint="list-document-tags"
            ndxField="tag_ndx"
          />
        </TableWrapper>
      </Panel>
    </>
  );
};

export default DocumentTags;
