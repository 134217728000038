import useFetchData from "../../../../../hooks/useFetchData";
import { useMemo } from "react";

export function useFetchDataAndCreateLookup({
  endpoint,
  dependencies = [],
  valueField,
  labelField,
  checkAuth = true,
}) {
  const [data, isLoading, handleDataUpdate] = useFetchData(
    endpoint,
    dependencies,
    checkAuth
  );

  const tableLookup = useMemo(() => {
    if (!data || !data?.length) return {};
    return data.reduce((acc, curr) => {
      acc[curr[valueField]] = curr[labelField];
      return acc;
    }, {});
  }, [data, valueField, labelField]);

  return {
    data,
    isLoading,
    handleDataUpdate,
    tableLookup,
  };
}
