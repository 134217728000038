import { useCallback } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useApp } from "../../../../../AppProvider";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
});

export const useSubmitActions = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();

  const handleRequest = useCallback(
    async (method, url, data, successMessage) => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        let response;
        if (data !== null && method !== "delete") {
          response = await axiosInstance[method](url, data, { headers });
        } else {
          response = await axiosInstance[method](url, { headers });
        }

        if (successMessage) {
          doToast("success", successMessage);
        }
        return response.data;
      } catch (err) {
        console.error(err);
        const errorMessage =
          err?.response?.data?.message ?? "Something went wrong";
        doToast("error", errorMessage);
        throw err;
      }
    },
    [getAccessTokenSilently, doToast]
  );

  const handleAdd = useCallback(
    async (certificateData, refetch) => {
      await handleRequest(
        "post",
        "/api/list-certificate",
        certificateData,
        "New entry was saved to the database"
      );
      refetch?.();
    },
    [handleRequest]
  );

  const handleUpdate = useCallback(
    async (certificateData, refetch) => {
      await handleRequest(
        "put",
        `/api/list-certificate/${certificateData.certificate_ndx}`,
        certificateData,
        "Data was updated in the database"
      );
      refetch?.();
    },
    [handleRequest]
  );

  const handleDelete = useCallback(
    async (certificateNdx, refetch) => {
      await handleRequest(
        "delete",
        `/api/list-certificate/${certificateNdx}`,
        null,
        "Entry was deleted from the database"
      );
      refetch?.();
    },
    [handleRequest]
  );

  return { handleAdd, handleUpdate, handleDelete };
};
