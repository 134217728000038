import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useApp } from "../../../AppProvider";
import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CopyIcon from "@material-ui/icons/FileCopy";
import {
  Accordion,
  AccordionDetails,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  TextField,
  Link,
  Button,
  AccordionSummary,
  Typography as MuiTypography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

import Panel from "../../../components/panels/Panel";
import Loader from "../../../components/Loader";
import { copyToClipboard, dateFormatter } from "../../../utils";
import useFetchData from "../../../hooks/useFetchData";
import NumberFormat from "react-number-format";
import LatLongMapDrawdownTool from "../../../components/map/LatLongMapDrawdownTool";
import Upload from "../../dataAccess/uploads/Upload";
import parse from "html-react-parser";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function NumberFormatCustom(props) {
  const { config, inputRef, ...other } = props;
  return (
    <NumberFormat
      getInputRef={inputRef}
      decimalScale={config.decimalScale ?? 0}
      fixedDecimalScale={config.fixedDecimalScale ?? false}
      max={5}
      {...other}
    />
  );
}

function Developments() {
  const { doToast } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const [selectedRow, setSelectedRow] = useState(null);

  const defaultRowValues = {
    development_ndx: null,
    development_desc: null,
    remark: null,
    lon: null,
    lat: null,
    archived: false,
    cai_initial_50pct_paid: false,
    cai_final_50pct_paid: false,
    cai_payment_remark: null,
  };

  const handleUpdateSelectedRow = (name, value) => {
    setSelectedRow((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const [listDevelopments] = useFetchData("list-developments", [], true);

  const [attachmentsDevelopments] = useFetchData(
    "attachments-developments",
    [],
    true
  );
  const attachmentsDevelopmentsLookup = useMemo(() => {
    let converted = {};
    if (attachmentsDevelopments?.length > 0) {
      attachmentsDevelopments.forEach((item) => {
        converted[item.development_ndx] = item.hypercodelist
          .split(",")
          .map((item) => <li key={item}>{parse(item)}</li>);
      });
    }
    return converted;
  }, [attachmentsDevelopments]);

  const { data } = useQuery(
    ["list-development"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-development`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const [filteredData, setFilteredData] = useState(null);
  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  const editTableColumns = [
    {
      title: "Development",
      field: "development_desc",
    },
    {
      title: "Notes",
      field: "remark",
      cellStyle: {
        minWidth: 400,
        maxWidth: 400,
      },
    },
    {
      title: "Longitude",
      field: "lon",
      render: (row) => (+row?.lon)?.toFixed(5) ?? "",
    },
    {
      title: "Latitude",
      field: "lat",
      render: (row) => (+row?.lat)?.toFixed(5) ?? "",
    },
    {
      title: "Archived",
      field: "archived",
      type: "boolean",
    },
    {
      title: "Common Area Irrigation Initial 50% Paid?",
      field: "cai_initial_50pct_paid",
      type: "boolean",
    },
    {
      title: "Common Area Irrigation Final 50% Paid?",
      field: "cai_final_50pct_paid",
      type: "boolean",
    },
    {
      title: "Common Area Irrigation Payment Notes",
      field: "cai_payment_remark",
    },
    {
      title: "Attachments",
      field: "certificate_ndx",
      lookup: attachmentsDevelopmentsLookup,
      cellStyle: {
        minWidth: 600,
        maxWidth: 600,
      },
    },
  ];

  const handleSubmit = () => {
    if (selectedRow.development_ndx) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  const handleAdd = () => {
    return (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data: addedEntry } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/list-development`,
          selectedRow,
          { headers }
        );
        //update the material table
        setFilteredData((prevState) => {
          let data = [...prevState];
          data.unshift(addedEntry);
          return data;
        });
        doToast("success", "New entry was saved to the database");
        //clear the currentRow and removes the form
        setSelectedRow(null);
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const yesNoOptions = [
    {
      value: true,
      option: "Yes",
    },
    {
      value: false,
      option: "No",
    },
  ];

  const handleUpdate = () => {
    return (async () => {
      try {
        if (selectedRow) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.put(
            `${process.env.REACT_APP_ENDPOINT}/api/list-development/${selectedRow.development_ndx}`,
            selectedRow,
            { headers }
          );
          //update the material table
          setFilteredData((prevState) => {
            const data = [...prevState];
            data[
              data.findIndex(
                (item) => item.development_ndx === selectedRow.development_ndx
              )
            ] = selectedRow;
            return data;
          });
          doToast("success", "New data was updated to the database");
          //clear the currentRow and removes the form
          setSelectedRow(null);
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.delete(
            `${process.env.REACT_APP_ENDPOINT}/api/list-development/${oldData.development_ndx}`,
            { headers }
          );
          //update the material table
          setFilteredData((prevState) => {
            const data = [...prevState];
            const index = oldData.tableData.id;
            data.splice(index, 1);
            return data;
          });
          doToast("success", "This entry was deleted from the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const disableSubmit =
    !selectedRow?.development_desc ||
    !selectedRow?.lon ||
    selectedRow?.lon > 180 ||
    selectedRow?.lon < -180 ||
    !selectedRow?.lat ||
    selectedRow?.lat > 90 ||
    selectedRow?.lat < -90;

  return (
    <React.Fragment>
      <Helmet title="Developments" />
      <Typography variant="h3" gutterBottom display="inline">
        Developments
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Developments</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Developments
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  {filteredData ? (
                    <MaterialTable
                      id="Developments"
                      title={`Developments ${dateFormatter(
                        new Date(),
                        "MM/DD/YYYY, h:mm A"
                      )}`}
                      columns={editTableColumns}
                      data={filteredData}
                      editable={{
                        onRowDelete: handleDelete,
                        isDeletable: (rowData) =>
                          !listDevelopments.find(
                            (item) =>
                              item.development_ndx === rowData?.development_ndx
                          )?.assoc_certificate_ndx?.length,
                      }}
                      localization={{
                        toolbar: { searchPlaceholder: "Search Developments" },
                      }}
                      components={{
                        Container: (props) => <div {...props} />,
                      }}
                      actions={[
                        {
                          icon: CopyIcon,
                          tooltip: "Copy Data",
                          isFreeAction: true,
                          onClick: () => {
                            try {
                              copyToClipboard(
                                filteredData,
                                editTableColumns,
                                () =>
                                  doToast(
                                    "success",
                                    "Data was copied to your clipboard."
                                  )
                              );
                            } catch (error) {
                              const message =
                                error?.message ?? "Something went wrong";
                              doToast("error", message);
                            }
                          },
                        },
                        () => ({
                          icon: "edit",
                          tooltip: "Edit",
                          onClick: (event, rowData) => {
                            setSelectedRow(rowData);
                          },
                        }),
                        {
                          icon: "add_box",
                          tooltip: "Add",
                          isFreeAction: true,
                          onClick: () => {
                            setSelectedRow(defaultRowValues);
                          },
                        },
                      ]}
                      options={{
                        emptyRowsWhenPaging: false,
                        columnsButton: true,
                        exportButton: true,
                        exportAllData: true,
                        addRowPosition: "first",
                        pageSize: 200,
                        pageSizeOptions: [10, 30, 50, 100, 200],
                        padding: "dense",
                        searchFieldAlignment: "left",
                        maxBodyHeight: "400px",
                        showTitle: false,
                      }}
                    />
                  ) : (
                    <Loader />
                  )}
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      {selectedRow && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="table-content"
                id="table-header"
              >
                <Typography variant="h4" ml={2}>
                  {!selectedRow.development_ndx
                    ? "New Development"
                    : `Editing Development "${selectedRow.development_desc}"`}
                </Typography>
              </AccordionSummary>
              <Panel>
                <AccordionDetails>
                  <Grid container spacing={4}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        required
                        error={!selectedRow.development_desc}
                        helperText={
                          !selectedRow.development_desc ? "*Required field" : ""
                        }
                        variant="outlined"
                        label="Development"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "development_desc",
                            e.target.value
                          )
                        }
                        value={selectedRow.development_desc || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        disabled
                        variant="outlined"
                        label="Associated Certificates"
                        style={{ width: "100%" }}
                        value={
                          listDevelopments.find(
                            (item) =>
                              item.development_ndx ===
                              selectedRow?.development_ndx
                          )?.assoc_certificate_no || "<No Associations>"
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        position: "relative",
                      }}
                    >
                      <LatLongMapDrawdownTool
                        filterValues={selectedRow}
                        setFilterValues={handleUpdateSelectedRow}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        name="longitude"
                        required
                        error={
                          !selectedRow.lon ||
                          selectedRow.lon > 180 ||
                          selectedRow.lon < -180
                        }
                        helperText={
                          !selectedRow.lon && selectedRow.lon !== 0
                            ? "*Required field"
                            : selectedRow.lon > 180 || selectedRow.lon < -180
                            ? "Invalid coordinate"
                            : ""
                        }
                        variant="outlined"
                        label="Longitude"
                        style={{ width: "100%" }}
                        InputProps={{
                          inputProps: {
                            config: {
                              decimalScale: 5,
                              fixedDecimalScale: true,
                            },
                          },
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("lon", e.target.value)
                        }
                        value={selectedRow.lon || ""}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        name="latitude"
                        required
                        error={
                          !selectedRow.lat ||
                          selectedRow.lat > 90 ||
                          selectedRow.lat < -90
                        }
                        helperText={
                          !selectedRow.lat && selectedRow.lat !== 0
                            ? "*Required field"
                            : selectedRow.lat > 90 || selectedRow.lat < -90
                            ? "Invalid coordinate"
                            : ""
                        }
                        variant="outlined"
                        label="Latitude"
                        style={{ width: "100%" }}
                        InputProps={{
                          inputProps: {
                            config: {
                              decimalScale: 5,
                              fixedDecimalScale: true,
                            },
                          },
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("lat", e.target.value)
                        }
                        value={selectedRow.lat || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={8}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Notes"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("remark", e.target.value)
                        }
                        value={selectedRow.remark || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={4}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        required
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={![false, true].includes(selectedRow.archived)}
                      >
                        <InputLabel id="archived">Archived?</InputLabel>
                        <Select
                          required
                          labelId="archived-label"
                          id="archived"
                          label="Archived?"
                          value={
                            [false, true].includes(selectedRow.archived)
                              ? selectedRow.archived
                              : ""
                          }
                          onChange={(e) =>
                            handleUpdateSelectedRow("archived", e.target.value)
                          }
                        >
                          {yesNoOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.option}
                            </MenuItem>
                          ))}
                        </Select>
                        {![false, true].includes(selectedRow.archived) && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        required
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={
                          ![false, true].includes(
                            selectedRow.cai_initial_50pct_paid
                          )
                        }
                      >
                        <InputLabel id="cai-initial-50pct-paid">
                          Common Area Irrigation Initial 50% Paid?
                        </InputLabel>
                        <Select
                          required
                          labelId="cai-initial-50pct-paid-label"
                          id="cai_initial_50pct_paid"
                          label="Common Area Irrigation Initial 50% Paid?"
                          value={
                            [false, true].includes(
                              selectedRow.cai_initial_50pct_paid
                            )
                              ? selectedRow.cai_initial_50pct_paid
                              : ""
                          }
                          onChange={(e) =>
                            handleUpdateSelectedRow(
                              "cai_initial_50pct_paid",
                              e.target.value
                            )
                          }
                        >
                          {yesNoOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.option}
                            </MenuItem>
                          ))}
                        </Select>
                        {![false, true].includes(
                          selectedRow.cai_initial_50pct_paid
                        ) && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        required
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={
                          ![false, true].includes(
                            selectedRow.cai_final_50pct_paid
                          )
                        }
                      >
                        <InputLabel id="cai-final-50pct-paid">
                          Common Area Irrigation Final 50% Paid?
                        </InputLabel>
                        <Select
                          required
                          labelId="cai-final-50pct-paid-label"
                          id="cai-final-50pct-paid"
                          label="Common Area Irrigation Final 50% Paid?"
                          value={
                            [false, true].includes(
                              selectedRow.cai_final_50pct_paid
                            )
                              ? selectedRow.cai_final_50pct_paid
                              : ""
                          }
                          onChange={(e) =>
                            handleUpdateSelectedRow(
                              "cai_final_50pct_paid",
                              e.target.value
                            )
                          }
                        >
                          {yesNoOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.option}
                            </MenuItem>
                          ))}
                        </Select>
                        {![false, true].includes(
                          selectedRow.cai_final_50pct_paid
                        ) && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Common Area Irrigation Payment Notes"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "cai_payment_remark",
                            e.target.value
                          )
                        }
                        value={selectedRow.cai_payment_remark || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{
                        position: "relative",
                      }}
                    />
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Button
                        style={{ width: "100%", height: "44px" }}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => setSelectedRow(null)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Button
                        style={{ width: "100%", height: "44px" }}
                        type="submit"
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={disableSubmit}
                      >
                        Save
                      </Button>
                    </Grid>
                    {selectedRow.development_ndx && (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{
                            position: "relative",
                          }}
                        >
                          <Divider my={6} />
                          <Upload
                            selectedNdx={selectedRow.development_ndx}
                            endpoint="developments-to-attachments"
                            awsFolder="developments"
                            attachmentType="development"
                            ndxName="development_ndx"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </AccordionDetails>
              </Panel>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default Developments;
