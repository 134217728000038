import React from "react";
import { useQuery } from "react-query";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const CertificatesTracking = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isFetching, error } = useQuery(
    ["report-certificate-log"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/report-certificate-log`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (error) return "An error has occurred: " + error.message;

  const tabColumns = [
    {
      title: "<25% Left",
      field: "low_balance_alert",
    },
    {
      title: "Certificate No.",
      field: "certificate_no",
    },
    {
      title: "Certificate Holder",
      field: "holder_desc",
    },
    {
      title: "Development",
      field: "development_desc",
    },
    {
      title: "Total Certificate Credit (AF)",
      field: "total_credits_acft",
    },
    {
      title: "Transaction Date",
      field: "log_date",
      type: "date",
    },
    {
      title: "Transaction",
      field: "transaction_description",
    },
    {
      title: "Transaction Amount (AF)",
      field: "log_amount_acft",
    },
    {
      title: "Remaining Credit (AF)",
      field: "remaining_acft",
    },
    {
      title: "Permit No.",
      field: "permit_no",
    },
    {
      title: "Lot Area (Sq Ft)",
      field: "lot_area_sqft",
    },
    {
      title: "Notes",
      field: "remarks",
      cellStyle: {
        minWidth: 600,
        maxWidth: 600,
      },
    },
  ];

  return (
    <>
      <Helmet title="Certificates Tracking" />
      <Typography variant="h3" gutterBottom display="inline">
        Certificates Tracking Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Certificates Tracking</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Panel>
        <TableWrapper>
          <Table
            pageSize={200}
            options={{ filtering: true }}
            label="Certificates Tracking"
            isLoading={isFetching}
            columns={tabColumns}
            data={data}
            height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
          />
        </TableWrapper>
      </Panel>
    </>
  );
};

export default CertificatesTracking;
