import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useApp } from "../../../AppProvider";
import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CopyIcon from "@material-ui/icons/FileCopy";
import {
  Accordion,
  AccordionDetails,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  TextField,
  Link,
  Button,
  AccordionSummary,
  Typography as MuiTypography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";

import Panel from "../../../components/panels/Panel";
import { copyToClipboard, dateFormatter } from "../../../utils";
import useFetchData from "../../../hooks/useFetchData";
import DatePicker from "../../../components/pickers/DatePicker";
import NumberFormat from "react-number-format";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import Upload from "../../dataAccess/uploads/Upload";
import parse from "html-react-parser";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function NumberFormatCustom(props) {
  const { config, inputRef, ...other } = props;
  return (
    <NumberFormat
      getInputRef={inputRef}
      decimalScale={config.decimalScale ?? 0}
      fixedDecimalScale={config.fixedDecimalScale ?? false}
      allowNegative={config.allowNegative ?? true}
      max={5}
      {...other}
    />
  );
}

function LogEntry() {
  const { doToast } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const [selectedRow, setSelectedRow] = useState(null);

  const defaultRowValues = {
    log_ndx: null,
    certificate_ndx: null,
    log_date: null,
    entry_type_ndx: null,
    to_certificate_ndx: null,
    log_amount_acft: 0.4,
    lot_area_sqft: null,
    lot_purpose: null,
    fee_remark: null,
    log_remark: null,
    remark: null,
    permit_no: null,
  };

  const handleUpdateSelectedRow = (name, value) => {
    setSelectedRow((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      if (name === "entry_type_ndx" && value !== 3) {
        newFilterValues["to_certificate_ndx"] = null;
      }
      if (name === "entry_type_ndx" && value !== 4) {
        newFilterValues["permit_no"] = null;
      }
      if (name === "entry_type_ndx" && [1, 2].includes(value)) {
        newFilterValues["lot_area_sqft"] = null;
      }
      return newFilterValues;
    });
  };

  const [listCertificates] = useFetchData("list-certificates", [], true);

  const [listEntryTypes] = useFetchData("list-entry-types", [], true);
  const listTypesLookup = useMemo(() => {
    let converted = {};
    if (listEntryTypes?.length > 0) {
      listEntryTypes.forEach((item) => {
        converted[item.entry_type_ndx] = item.description;
      });
    }
    return converted;
  }, [listEntryTypes]);

  const [listEntryTypesForEdits] = useFetchData(
    "list-entry-types-for-edits",
    [],
    true
  );

  const [attachmentsLogEntries] = useFetchData(
    "attachments-log-entries",
    [],
    true
  );
  const attachmentsLogEntriesLookup = useMemo(() => {
    let converted = {};
    if (attachmentsLogEntries?.length > 0) {
      attachmentsLogEntries.forEach((item) => {
        converted[item.log_ndx] = item.hypercodelist
          .split(",")
          .map((item) => <li key={item}>{parse(item)}</li>);
      });
    }
    return converted;
  }, [attachmentsLogEntries]);

  const [selectedCertificate, setSelectedCertificate] = useState(null);
  useEffect(() => {
    if (selectedCertificate && selectedCertificate !== "") {
      refetch();
    }
  }, [selectedCertificate]); //eslint-disable-line

  const [refreshSwitch, setRefreshSwitch] = useState(false);
  const { data, refetch, isFetching } = useQuery(
    [
      `data-certificate-log/log-entry/${selectedCertificate}`,
      selectedCertificate,
      refreshSwitch,
    ],
    async () => {
      if (selectedCertificate && selectedCertificate !== "") {
        try {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };

          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT}/api/data-certificate-log/log-entry/${selectedCertificate.certificate_ndx}`,
            { headers }
          );

          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const editTableColumns = [
    {
      title: "Entry Type",
      field: "entry_type_ndx",
      lookup: listTypesLookup,
    },
    {
      title: "Transaction Date",
      field: "log_date",
      type: "date",
    },
    {
      title: "Amount (AF)",
      field: "log_amount_acft",
      render: (row) =>
        row.log_amount_acft === null
          ? "N/A"
          : (+row?.log_amount_acft)?.toFixed(3),
    },
    {
      title: "Purpose/Lot and Block",
      field: "lot_purpose",
    },
    {
      title: "Lot Area (Sq Ft)",
      field: "lot_area_sqft",
      render: (row) =>
        row.lot_area_sqft === null ? "N/A" : (+row?.lot_area_sqft)?.toFixed(1),
    },
    {
      title: "Remaining (AF)",
      field: "remaining_acft",
      render: (row) =>
        row.remaining_acft === null
          ? "N/A"
          : (+row?.remaining_acft)?.toFixed(3),
    },
    {
      title: "Entry Notes",
      field: "log_remark",
    },
    {
      title: "Fee Notes",
      field: "fee_remark",
    },
    {
      title: "Data Notes",
      field: "remark",
    },
    {
      title: "Permit Number",
      field: "permit_no",
    },
    {
      title: "Attachments",
      field: "log_ndx",
      lookup: attachmentsLogEntriesLookup,
      cellStyle: {
        minWidth: 600,
        maxWidth: 600,
      },
    },
  ];

  const handleSubmit = () => {
    if (selectedRow.log_ndx) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  const handleAdd = () => {
    return (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/data-certificate-log/log-entry`,
          {
            ...selectedRow,
            ...{ certificate_ndx: selectedCertificate.certificate_ndx },
          },
          { headers }
        );
        //update the material table
        setRefreshSwitch((state) => !state);
        doToast("success", "New entry was saved to the database");
        //clear the currentRow and removes the form
        setSelectedRow(null);
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleUpdate = () => {
    return (async () => {
      try {
        if (selectedRow) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.put(
            `${process.env.REACT_APP_ENDPOINT}/api/data-certificate-log/log-entry/${selectedRow.log_ndx}`,
            selectedRow,
            { headers }
          );
          //update the material table
          setRefreshSwitch((state) => !state);
          doToast("success", "New data was updated to the database");
          //clear the currentRow and removes the form
          setSelectedRow(null);
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.delete(
            `${process.env.REACT_APP_ENDPOINT}/api/data-certificate-log/log-entry/${oldData.log_ndx}`,
            { headers }
          );
          //update the material table
          setRefreshSwitch((state) => !state);
          doToast("success", "This entry was deleted from the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const disableSubmit =
    (!selectedRow?.log_date && selectedRow?.entry_type_ndx !== 6) ||
    !selectedRow?.entry_type_ndx ||
    (selectedRow.entry_type_ndx !== 6 && !selectedRow.log_amount_acft) ||
    (selectedRow?.entry_type_ndx === 2 && !selectedRow?.to_certificate_ndx) ||
    (![1, 2, 6].includes(selectedRow.entry_type_ndx) &&
      !selectedRow.log_ndx &&
      !selectedRow.lot_area_sqft);

  return (
    <React.Fragment>
      <Helmet title="Log Entry" />
      <Typography variant="h3" gutterBottom display="inline">
        Log Entry
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Log Entry</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Log Entry
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  {listCertificates.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Typography variant="subtitle1">
                        Log Entry for:
                      </Typography>
                      <Autocomplete
                        style={{ marginTop: "5px" }}
                        mt={3}
                        id="well"
                        options={listCertificates}
                        getOptionLabel={(option) => option.list_display}
                        onChange={(e, value) => {
                          setSelectedCertificate(value);
                        }}
                        value={selectedCertificate}
                        renderInput={(params) => (
                          <TextField
                            style={{ width: "100%" }}
                            {...params}
                            variant="outlined"
                            label="Certificate:"
                          />
                        )}
                      />
                    </Grid>
                  )}

                  {data && (
                    <MaterialTable
                      isLoading={isFetching}
                      id="Certificates"
                      title={`Certificates ${dateFormatter(
                        new Date(),
                        "MM/DD/YYYY, h:mm A"
                      )}`}
                      columns={editTableColumns}
                      data={data}
                      editable={{
                        onRowDelete: handleDelete,
                        isDeletable: (rowData) => rowData.entry_type_ndx !== 3,
                      }}
                      localization={{
                        toolbar: { searchPlaceholder: "Search Log Entries" },
                      }}
                      components={{
                        Container: (props) => <div {...props} />,
                      }}
                      actions={[
                        {
                          icon: CopyIcon,
                          tooltip: "Copy Data",
                          isFreeAction: true,
                          onClick: () => {
                            try {
                              copyToClipboard(data, editTableColumns, () =>
                                doToast(
                                  "success",
                                  "Data was copied to your clipboard."
                                )
                              );
                            } catch (error) {
                              const message =
                                error?.message ?? "Something went wrong";
                              doToast("error", message);
                            }
                          },
                        },
                        (rowData) => ({
                          icon: "edit",
                          tooltip: "Edit",
                          disabled: rowData.entry_type_ndx === 3,
                          onClick: () => {
                            setSelectedRow(rowData);
                          },
                        }),
                        {
                          icon: "add_box",
                          tooltip: "Add",
                          isFreeAction: true,
                          onClick: () => {
                            setSelectedRow(defaultRowValues);
                          },
                        },
                      ]}
                      options={{
                        emptyRowsWhenPaging: false,
                        showTitle: false,
                        columnsButton: true,
                        exportButton: true,
                        exportAllData: true,
                        addRowPosition: "first",
                        pageSize: 200,
                        pageSizeOptions: [10, 30, 50, 100, 200],
                        padding: "dense",
                        searchFieldAlignment: "left",
                        maxBodyHeight: "400px",
                      }}
                    />
                  )}
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      {selectedRow && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="table-content"
                id="table-header"
              >
                <Typography variant="h4" ml={2}>
                  {!selectedRow.certificate_ndx
                    ? "New Log Entry"
                    : selectedRow.log_date
                    ? `Editing Log Entry on ${dateFormatter(
                        selectedRow.log_date,
                        "MM-DD-YYYY"
                      )}`
                    : selectedRow.lot_purpose
                    ? `Editing Log Entry ${selectedRow.lot_purpose}`
                    : "Editing Log Entry"}
                </Typography>
              </AccordionSummary>
              <Panel>
                <AccordionDetails>
                  <Grid container spacing={4}>
                    <Grid item xs={6} lg={3}>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={!selectedRow.entry_type_ndx}
                      >
                        <InputLabel id="entry-type">Entry Type</InputLabel>
                        <Select
                          required
                          error={!selectedRow.entry_type_ndx}
                          labelId="entry-type-label"
                          id="entry-type"
                          label="Entry Type"
                          value={selectedRow.entry_type_ndx || ""}
                          onChange={(e) =>
                            handleUpdateSelectedRow(
                              "entry_type_ndx",
                              e.target.value
                            )
                          }
                        >
                          {listEntryTypesForEdits.map((option) => (
                            <MenuItem
                              key={option.entry_type_ndx}
                              value={option.entry_type_ndx}
                            >
                              {option.description}
                            </MenuItem>
                          ))}
                        </Select>
                        {!selectedRow.entry_type_ndx && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      lg={3}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={
                          [1, 2].includes(selectedRow.entry_type_ndx)
                            ? "If exact date is unknown, select the first date of the appropriate month or year."
                            : ""
                        }
                      >
                        <div>
                          <DatePicker
                            required={selectedRow.entry_type_ndx !== 6}
                            error={
                              !selectedRow.log_date &&
                              selectedRow.entry_type_ndx !== 6
                            }
                            label="Transaction Date"
                            name="log_date"
                            selectedDate={selectedRow.log_date}
                            setSelectedDate={handleUpdateSelectedRow}
                          />
                        </div>
                      </Tooltip>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      lg={3}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        name="amount"
                        required={selectedRow.entry_type_ndx !== 6}
                        error={
                          (selectedRow.entry_type_ndx !== 6 &&
                            !selectedRow.log_amount_acft) ||
                          selectedRow.log_amount_acft < 0
                        }
                        helperText={
                          selectedRow.entry_type_ndx !== 6 &&
                          !selectedRow.log_amount_acft
                            ? "*Required field"
                            : selectedRow.log_amount_acft < 0
                            ? "Invalid entry"
                            : ""
                        }
                        variant="outlined"
                        label="Amount, Acre-Feet"
                        style={{ width: "100%" }}
                        InputProps={{
                          inputProps: {
                            config: {
                              decimalScale: 3,
                              fixedDecimalScale: true,
                              allowNegative: false,
                            },
                          },
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "log_amount_acft",
                            e.target.value
                          )
                        }
                        value={selectedRow.log_amount_acft || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      lg={3}
                      style={{
                        position: "relative",
                        display: ![1, 2].includes(selectedRow.entry_type_ndx)
                          ? "block"
                          : "none",
                      }}
                    >
                      <TextField
                        required={selectedRow.entry_type_ndx !== 6}
                        name="area"
                        variant="outlined"
                        label="Lot Area (SqFt)"
                        style={{ width: "100%" }}
                        error={
                          ![1, 2, 6].includes(selectedRow.entry_type_ndx) &&
                          !selectedRow.log_ndx &&
                          !selectedRow.lot_area_sqft
                        }
                        helperText={
                          ![1, 2, 6].includes(selectedRow.entry_type_ndx) &&
                          !selectedRow.log_ndx &&
                          !selectedRow.lot_area_sqft
                            ? "*Required field"
                            : ""
                        }
                        disabled={[1, 2].includes(selectedRow.entry_type_ndx)}
                        InputProps={{
                          inputProps: {
                            config: {
                              decimalScale: 1,
                              fixedDecimalScale: true,
                              allowNegative: false,
                            },
                          },
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "lot_area_sqft",
                            e.target.value
                          )
                        }
                        value={selectedRow.lot_area_sqft || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      lg={12}
                      style={{
                        position: "relative",
                        display:
                          selectedRow.entry_type_ndx === 2 ? "block" : "none",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={!selectedRow.to_certificate_ndx}
                      >
                        <InputLabel id="entry-type">
                          Certificate to Transfer To
                        </InputLabel>
                        <Select
                          required
                          error={!selectedRow.to_certificate_ndx}
                          labelId="transter-to-certificate"
                          id="transfer-to-certificate"
                          label="Certificate to Transfer To"
                          value={selectedRow.to_certificate_ndx || ""}
                          onChange={(e) =>
                            handleUpdateSelectedRow(
                              "to_certificate_ndx",
                              e.target.value
                            )
                          }
                        >
                          {listCertificates.map((option) => (
                            <MenuItem
                              key={option.certificate_ndx}
                              value={option.certificate_ndx}
                            >
                              {option.list_display}
                            </MenuItem>
                          ))}
                        </Select>
                        {!selectedRow.to_certificate_ndx && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      lg={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Purpose/Lot and Block"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("lot_purpose", e.target.value)
                        }
                        value={selectedRow.lot_purpose || ""}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Conversion Fees (or other fee notes)"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("fee_remark", e.target.value)
                        }
                        value={selectedRow.fee_remark || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      lg={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Log Notes of Interest (like water origin or unique details)"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("log_remark", e.target.value)
                        }
                        value={selectedRow.log_remark || ""}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Data Notes and Assumptions"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("remark", e.target.value)
                        }
                        value={selectedRow.remark || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      lg={3}
                      style={{
                        position: "relative",
                        display:
                          selectedRow.entry_type_ndx === 4 ? "block" : "none",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Permit Number"
                        style={{ width: "100%" }}
                        disabled={selectedRow.entry_type_ndx !== 4}
                        onChange={(e) =>
                          handleUpdateSelectedRow("permit_no", e.target.value)
                        }
                        value={selectedRow.permit_no || ""}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={9}
                      style={{
                        position: "relative",
                        display:
                          selectedRow.entry_type_ndx === 4 ? "block" : "none",
                      }}
                    />

                    <Grid
                      item
                      xs={12}
                      md={1}
                      style={{
                        position: "relative",
                      }}
                    />
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Button
                        style={{ width: "100%", height: "44px" }}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => setSelectedRow(null)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Button
                        style={{ width: "100%", height: "44px" }}
                        type="submit"
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={disableSubmit}
                      >
                        Save
                      </Button>
                    </Grid>
                    {selectedRow.log_ndx ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{
                            position: "relative",
                          }}
                        >
                          <Divider my={6} />
                          <Upload
                            selectedItem={selectedCertificate.certificate_ndx}
                            itemName="certificate_ndx"
                            selectedNdx={selectedRow.log_ndx}
                            endpoint="log-entries-to-attachments"
                            awsFolder="log-entries"
                            attachmentType="log entry"
                            ndxName="log_ndx"
                          />
                        </Grid>
                      </>
                    ) : (
                      <Alert
                        severity="info"
                        style={{ marginTop: "8px", marginBottom: "12px" }}
                      >
                        <AlertTitle>
                          Attachments can only be added to{" "}
                          <strong> existing log entries </strong>.
                        </AlertTitle>
                        To add an attachment (e.g. a permit or single lot
                        certificate) to this log entry, please{" "}
                        <strong> create & save </strong> the new entry. You can
                        then select the entry above for{" "}
                        <strong> editing </strong>, and attach files during the{" "}
                        <strong> editing process </strong>.
                      </Alert>
                    )}
                  </Grid>
                </AccordionDetails>
              </Panel>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default LogEntry;
