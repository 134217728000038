export const BASEMAP_STYLES = [
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

export const DEFAULT_MAP_CENTER = [-105.0811, 40.3083];

export const INIT_MAP_CONFIG = {
  style: BASEMAP_STYLES[0].url,
  center: DEFAULT_MAP_CENTER,
  zoom: 11.5,
  preserveDrawingBuffer: true,
};

export const WELLS_LAYER_ID = "berthoud-locations-circle";
export const WELLS_LABELS_LAYER_ID = "berthoud-locations-symbol";
export const INIT_FILTER_VALUES = {
  locationTypes: {
    label: "Location Types",
    name: "locationTypes",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "locationtype",
    options: [],
    type: "multi-select",
    value: [],
  },
  organizations: {
    name: "organizations",
    label: "Organizations",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "organizations",
    options: [],
    type: "multi-select-array",
    value: [],
  },
  huc10s: {
    name: "huc10s",
    label: "HUC 10s",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "huc10_name",
    options: [],
    type: "multi-select",
    value: [],
  },
  sources: {
    name: "sources",
    label: "Sources",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "sources",
    options: [],
    type: "multi-select-array",
    value: [],
  },
  parameterGroups: {
    name: "parameterGroups",
    label: "Parameter Groups",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "parameter_groups",
    options: [],
    type: "multi-select-array",
    value: [],
  },
  graphMode: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "ndx",
    type: "graphMode",
    value: [""],
  },
};

const layerId = "berthoud-locations-circle";
export const INIT_STYLE_VALUES = {
  default: {
    id: "default",
    layerId,
    layerFieldName: "",
    name: "Default",
    paint: {
      "circle-color": "#1e8dd2",
    },
  },
  locationTypes: {
    id: "locationTypes",
    layerId,
    layerFieldName: "locationtype",
    name: "Location Types",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "locationtype"],
        "No Values Available",
        "#fff",
        "#fff",
      ],
    },
  },
  organizations: {
    id: "organizations",
    layerId,
    layerFieldName: "organizations",
    name: "Organizations",
    options: [],
    type: "multi-select-array",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "org_fav"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  huc10s: {
    id: "huc10s",
    layerId,
    layerFieldName: "huc10_name",
    name: "HUC10s",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "huc10_name"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  sources: {
    id: "sources",
    layerId,
    layerFieldName: "sources",
    name: "Sources",
    options: [],
    type: "multi-select-array",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "source_fav"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  parameterGroups: {
    id: "parameterGroups",
    layerId,
    layerFieldName: "parameterGroups",
    name: "Parameter Groups",
    options: [],
    type: "multi-select-array",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "parameter_group_fav"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
};
