import { useCallback, useState } from "react";
import useFetchData from "../../../../../hooks/useFetchData";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useFetchDataAndCreateLookup } from "./useFetchDataAndCreateLookup";

export const useCertificates = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedItem, setSelectedItem] = useState(null);
  const [triggerRefetchLookups, setTriggerRefetchLookups] = useState(false);

  const [listCertificates] = useFetchData("list-certificates", [], true);

  const { data: listHolders, tableLookup: holderLookup } =
    useFetchDataAndCreateLookup({
      endpoint: "list-holders",
      dependencies: [triggerRefetchLookups],
      valueField: "holder_ndx",
      labelField: "holder_desc",
    });

  const { data: listDevelopments, tableLookup: developmentLookup } =
    useFetchDataAndCreateLookup({
      endpoint: "list-developments",
      dependencies: [triggerRefetchLookups],
      valueField: "development_ndx",
      labelField: "development_desc",
    });

  const { tableLookup: attachmentsCertificatesLookup } =
    useFetchDataAndCreateLookup({
      endpoint: "attachments-certificates",
      valueField: "certificate_ndx",
      labelField: "hypercodelist",
    });

  const fetchData = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      const { data } = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/list-certificate`,
        { headers }
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }, [getAccessTokenSilently]);

  const {
    data,
    isFetching,
    error,
    refetch: refetchTable,
  } = useQuery(["list-certificate"], fetchData, {
    refetchOnWindowFocus: false,
  });

  const refetchCertificatesAndLookups = useCallback(async () => {
    await refetchTable();
    setTriggerRefetchLookups((prevValue) => !prevValue);
  }, [refetchTable]);

  return {
    data,
    isLoading: isFetching,
    error,
    refetchTable,
    refetchCertificatesAndLookups,
    selectedItem,
    setSelectedItem,
    tableLookups: {
      holderLookup,
      developmentLookup,
      attachmentsCertificatesLookup,
    },
    inputLookups: {
      listCertificates,
      listHolders,
      listDevelopments,
    },
  };
};
