import React, { useState } from "react";

import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Card as MuiCard,
  CardContent,
} from "@material-ui/core";
import { useQuery } from "react-query";
import axios from "axios";
import Loader from "../../../components/Loader";
import MaterialTable from "material-table";
import CopyIcon from "@material-ui/icons/FileCopy";
import { copyToClipboard } from "../../../utils";
import { useApp } from "../../../AppProvider";
import DashboardMap from "./DashboardMap";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Card = styled(MuiCard)(spacing);

const MapContainer = styled.div`
  height: 400px;
  width: 100%;
`;

function Default() {
  const { user, getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();

  const { data, isFetching, error } = useQuery(
    ["ui-map-and-certificate-summary"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/ui-map-and-certificate-summary`,
          { headers }
        );
        return data.filter((location) => location.location_geometry);
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const columns = [
    {
      title: "Cert No",
      field: "certificate_no",
    },
    {
      title: "Holder",
      field: "holder_desc",
    },
    {
      title: "Development",
      field: "development_desc",
    },
    {
      title: "Credits (AF)",
      field: "total_credits_acft",
    },
    {
      title: "Debits (AF)",
      field: "total_debits_acft",
    },
    {
      title: "Remaining (AF)",
      field: "current_remaining_acft",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Low Balance Alert",
      field: "low_balance_alert",
      defaultSort: "desc",
    },
  ];

  const [showPending, setShowPending] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [flyToCoordinates, setFlyToCoordinates] = useState(null);

  const handleShowPending = () => {
    setShowPending((prevState) => {
      return !prevState;
    });
  };

  const handleShowAll = () => {
    setShowPending(false);
    setShowAll((prevState) => {
      return !prevState;
    });
  };

  const filterData = (data) => {
    if (showAll) {
      return data;
    } else if (showPending) {
      return data.filter((d) => d.active === true || d.status === "Pending");
    } else if (!showPending) {
      return data.filter((d) => d.active === true);
    }
    return data;
  };

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          <Typography variant="subtitle1">
            Welcome back, {user?.name}!
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        {data ? (
          <>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h3" gutterBottom>
                    Water Credits Accounting Log
                  </Typography>

                  <MaterialTable
                    id="Water Credits Accounting Log"
                    title="Water Credits Accounting Log"
                    columns={columns}
                    isLoading={isFetching}
                    data={filterData(data)}
                    editable={{}}
                    components={{
                      Container: (props) => <div {...props} />,
                    }}
                    actions={[
                      {
                        icon: CopyIcon,
                        tooltip: "Copy Data",
                        isFreeAction: true,
                        onClick: () => {
                          try {
                            copyToClipboard(data, columns, () =>
                              doToast(
                                "success",
                                "Data was copied to your clipboard."
                              )
                            );
                          } catch (error) {
                            const message =
                              error?.message ?? "Something went wrong";
                            doToast("error", message);
                          }
                        },
                      },
                      {
                        icon: showPending ? "toggle_on" : "toggle_off",
                        iconProps: {
                          style: {
                            color: showPending ? "#4CAF50" : "currentcolor",
                          },
                        },
                        tooltip: "Show Pending Records",
                        isFreeAction: true,
                        onClick: handleShowPending,
                        disabled: showAll,
                      },
                      {
                        icon: showAll ? "toggle_on" : "toggle_off",
                        iconProps: {
                          style: {
                            color: showAll ? "#4CAF50" : "currentcolor",
                          },
                        },
                        tooltip: "Show All Records",
                        isFreeAction: true,
                        onClick: handleShowAll,
                      },
                    ]}
                    options={{
                      selection: true,
                      emptyRowsWhenPaging: false,
                      exportAllData: true,
                      columnsButton: true,
                      exportButton: true,
                      pageSize: 200,
                      pageSizeOptions: [10, 30, 50, 100, 200],
                      padding: "dense",
                      searchFieldAlignment: "left",
                      showTitle: false,
                      maxBodyHeight: "400px",
                      rowStyle: (rowData) => ({
                        backgroundColor:
                          selectedRow === rowData.tableData.id
                            ? "#fff0b9"
                            : rowData.low_balance_alert === "Yes"
                            ? "#ffdee2"
                            : "#FFF",
                      }),
                    }}
                    onSelectionChange={(rows) =>
                      setSelectedLocations(
                        rows.map((location) => location.certificate_ndx)
                      )
                    }
                    onRowClick={(evt, selectedRow) => {
                      setSelectedRow(selectedRow.tableData.id);
                      setFlyToCoordinates(
                        selectedRow.location_geometry.coordinates
                      );
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <MapContainer>
                    <DashboardMap
                      data={data}
                      error={error}
                      isLoading={isFetching}
                      selectedLocations={selectedLocations}
                      flyToCoordinates={flyToCoordinates}
                    />
                  </MapContainer>
                </CardContent>
              </Card>
            </Grid>
          </>
        ) : (
          <Loader />
        )}
      </Grid>
    </React.Fragment>
  );
}

export default Default;
