import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useApp } from "../../../AppProvider";
import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CopyIcon from "@material-ui/icons/FileCopy";
import {
  Accordion,
  AccordionDetails,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  TextField,
  Link,
  Button,
  AccordionSummary,
  Typography as MuiTypography,
  Box,
} from "@material-ui/core";

import Panel from "../../../components/panels/Panel";
import { copyToClipboard, dateFormatter } from "../../../utils";
import useFetchData from "../../../hooks/useFetchData";
import { Autocomplete } from "@material-ui/lab";
import { Repeat } from "react-feather";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function BulkLotTransfer() {
  const { doToast } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelect = (rows) => {
    setSelectedRows(rows);
  };

  const [listCertificates] = useFetchData("list-certificates", [], true);

  const [listEntryTypes] = useFetchData("list-entry-types", [], true);

  const listTypesLookup = useMemo(() => {
    let converted = {};
    if (listEntryTypes?.length > 0) {
      listEntryTypes.forEach((item) => {
        converted[item.entry_type_ndx] = item.description;
      });
    }
    return converted;
  }, [listEntryTypes]);

  const [selectedCertificate, setSelectedCertificate] = useState(null);
  useEffect(() => {
    if (selectedCertificate && selectedCertificate !== "") {
      refetch();
      handleRowSelect([]);
    }
  }, [selectedCertificate]); //eslint-disable-line

  const [transferToCertificate, setTransferToCertificate] = useState(null);

  const [refreshSwitch, setRefreshSwitch] = useState(false);
  const { data, refetch, isFetching } = useQuery(
    [
      `data-certificate-log/log-entry/${selectedCertificate}`,
      selectedCertificate,
      refreshSwitch,
    ],
    async () => {
      if (selectedCertificate && selectedCertificate !== "") {
        try {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };

          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT}/api/data-certificate-log/log-entry/${selectedCertificate.certificate_ndx}`,
            { headers }
          );
          return data.filter((item) => [4, 6, 8].includes(item.entry_type_ndx));
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const columns = [
    {
      title: "Entry Type",
      field: "entry_type_ndx",
      lookup: listTypesLookup,
    },
    {
      title: "Transaction Date",
      field: "log_date",
      type: "date",
    },
    {
      title: "Amount (AF)",
      field: "log_amount_acft",
      render: (row) =>
        row.log_amount_acft === null
          ? "N/A"
          : (+row?.log_amount_acft)?.toFixed(2),
    },
    {
      title: "Purpose/Lot and Block",
      field: "lot_purpose",
    },
    {
      title: "Lot Area (SqFt)",
      field: "lot_area_sqft",
      render: (row) =>
        row.lot_area_sqft === null ? "N/A" : (+row?.lot_area_sqft)?.toFixed(1),
    },
    {
      title: "Remaining (AF)",
      field: "remaining_acft",
      render: (row) =>
        row.remaining_acft === null
          ? "N/A"
          : (+row?.remaining_acft)?.toFixed(2),
    },
    {
      title: "Entry Notes",
      field: "log_remark",
    },
    {
      title: "Fee Notes",
      field: "fee_remark",
    },
    {
      title: "Data Notes",
      field: "remark",
    },
    {
      title: "Permit Number",
      field: "permit_no",
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const handleTransfer = () => {
    return (async () => {
      try {
        if (selectedRows) {
          setIsLoading(true);
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.put(
            `${process.env.REACT_APP_ENDPOINT}/api/data-certificate-log/bulk-transfers`,
            {
              certificate: transferToCertificate.certificate_ndx,
              ndxs: selectedRows.map((row) => row.log_ndx),
            },
            { headers }
          );
          //update the material table
          setRefreshSwitch((state) => !state);
          doToast("success", "New data was updated to the database");
          //clear the currentRow and removes the form
          handleRowSelect([]);
          setIsLoading(false);
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
        setIsLoading(false);
      }
    })();
  };

  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const toggleDisplayedRowsOnly = () => {
    setShowOnlySelected(!showOnlySelected);
  };

  const filteredData = useMemo(() => {
    if (showOnlySelected && selectedRows.length > 0) {
      return selectedRows;
    }
    return data;
  }, [showOnlySelected, data, selectedRows]);

  return (
    <React.Fragment>
      <Helmet title="Bulk Lot Transfer" />
      <Typography variant="h3" gutterBottom display="inline">
        Bulk Lot Transfer
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Bulk Lot Transfer</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Bulk Lot Transfer
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  {listCertificates.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Typography variant="subtitle1">
                        Log Entry for:
                      </Typography>
                      <Autocomplete
                        style={{ marginTop: "5px" }}
                        mt={3}
                        id="well"
                        options={listCertificates}
                        getOptionLabel={(option) => option.list_display}
                        onChange={(e, value) => {
                          setSelectedCertificate(value);
                        }}
                        value={selectedCertificate}
                        renderInput={(params) => (
                          <TextField
                            style={{ width: "100%" }}
                            {...params}
                            variant="outlined"
                            label="Certificate:"
                          />
                        )}
                      />
                    </Grid>
                  )}

                  {data && (
                    <>
                      <MaterialTable
                        isLoading={isFetching}
                        id="Certificates"
                        title={`Certificates ${dateFormatter(
                          new Date(),
                          "MM/DD/YYYY, h:mm A"
                        )}`}
                        columns={columns}
                        data={filteredData}
                        localization={{
                          toolbar: { searchPlaceholder: "Search Log Entries" },
                        }}
                        // components={{
                        //   Container: (props) => <div {...props} />,
                        // }}
                        onSelectionChange={handleRowSelect}
                        actions={[
                          {
                            icon: CopyIcon,
                            tooltip: "Copy Data",
                            isFreeAction: true,
                            onClick: () => {
                              try {
                                copyToClipboard(data, columns, () =>
                                  doToast(
                                    "success",
                                    "Data was copied to your clipboard."
                                  )
                                );
                              } catch (error) {
                                const message =
                                  error?.message ?? "Something went wrong";
                                doToast("error", message);
                              }
                            },
                          },
                          {
                            icon: showOnlySelected ? "toggle_on" : "toggle_off",
                            iconProps: {
                              style: {
                                color: showOnlySelected
                                  ? "#4CAF50"
                                  : "currentcolor",
                              },
                            },
                            tooltip: showOnlySelected
                              ? "Display all records"
                              : "Display selected records",
                            // isFreeAction: true,
                            onClick: toggleDisplayedRowsOnly,
                            position: "toolbarOnSelect",
                          },
                        ]}
                        options={{
                          emptyRowsWhenPaging: false,
                          showTitle: false,
                          columnsButton: true,
                          exportButton: true,
                          exportAllData: true,
                          addRowPosition: "first",
                          pageSize: 200,
                          pageSizeOptions: [10, 30, 50, 100, 200],
                          padding: "dense",
                          searchFieldAlignment: "left",
                          maxBodyHeight: "400px",
                          selection: true,
                        }}
                      />
                      {listCertificates.length > 0 && (
                        <>
                          <Grid
                            item
                            xs={12}
                            style={{
                              position: "relative",
                              marginTop: "16px",
                            }}
                          >
                            <Typography variant="subtitle1">
                              Transfer entries to:
                            </Typography>
                            <Autocomplete
                              style={{ marginTop: "5px" }}
                              mt={3}
                              id="well"
                              options={listCertificates}
                              getOptionLabel={(option) => option.list_display}
                              onChange={(e, value) => {
                                setTransferToCertificate(value);
                              }}
                              value={transferToCertificate}
                              renderInput={(params) => (
                                <TextField
                                  style={{ width: "100%" }}
                                  {...params}
                                  variant="outlined"
                                  label="Certificate:"
                                />
                              )}
                            />
                          </Grid>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              marginTop: "16px",
                            }}
                          >
                            <Button
                              disabled={
                                isLoading ||
                                !selectedRows.length ||
                                !transferToCertificate ||
                                transferToCertificate.certificate_ndx ===
                                  selectedCertificate.certificate_ndx
                              }
                              startIcon={<Repeat />}
                              onClick={handleTransfer}
                              variant="contained"
                              color="secondary"
                            >
                              Transfer
                            </Button>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default BulkLotTransfer;
