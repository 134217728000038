import { Grid, TextField } from "@material-ui/core";
import React from "react";

const NewHolder = React.memo(
  ({
    tmpCertHolderDesc,
    certHolderAddNew,
    tmpCertHolderRemark,
    handleUpdateEditorState,
  }) => {
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            New Certificate Holder
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              error={!tmpCertHolderDesc && certHolderAddNew}
              helperText={!tmpCertHolderDesc ? "*Required field" : ""}
              variant="outlined"
              label="Certificate Holder"
              color="primary"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateEditorState("tmp_cert_holder_desc", e.target.value)
              }
              value={tmpCertHolderDesc || ""}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              label="Notes"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateEditorState(
                  "tmp_cert_holder_remark",
                  e.target.value
                )
              }
              value={tmpCertHolderRemark || ""}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default NewHolder;
