import React from "react";
import Upload from "../../../../dataAccess/uploads/Upload";
import { Divider as MuiDivider, Grid } from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

const Divider = styled(MuiDivider)(spacing);

const UploadSection = React.memo(({ certificateNdx }) => {
  return (
    <Grid
      item
      xs={12}
      md={12}
      style={{
        position: "relative",
      }}
    >
      <Divider my={6} />
      <Upload
        selectedNdx={certificateNdx}
        endpoint="certificates-to-attachments"
        awsFolder="certificates"
        attachmentType="certificate"
        ndxName="certificate_ndx"
      />
    </Grid>
  );
});

export default UploadSection;
