import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { YES_NO_OPTIONS } from "../constants";
import React from "react";

const NewDevelopment = React.memo(
  ({
    tmpDevelopmentDesc,
    developmentAddNew,
    tmpDevelopmentRemark,
    tmpDevelopmentCaiInitial50PctPaid,
    tmpDevelopmentCaiFinal50PctPaid,
    tmpDevelopmentCaiPaymentRemark,
    handleUpdateEditorState,
  }) => {
    return (
      <Grid item xs={12} style={{ marginBottom: "1rem" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            New Development
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              error={!tmpDevelopmentDesc && developmentAddNew}
              helperText={!tmpDevelopmentDesc ? "*Required field" : ""}
              label="Development"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateEditorState("tmp_development_desc", e.target.value)
              }
              value={tmpDevelopmentDesc || ""}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Notes"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateEditorState(
                  "tmp_development_remark",
                  e.target.value
                )
              }
              value={tmpDevelopmentRemark || ""}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              error={false}
              style={{ width: "100%" }}
            >
              <InputLabel id="cai-initial-50pct-paid">
                Common Area Irrigation Initial 50% Paid?
              </InputLabel>
              <Select
                labelId="cai-initial-50pct-paid-label"
                id="cai_initial_50pct_paid"
                label="Common Area Irrigation Initial 50% Paid?"
                value={
                  [false, true].includes(tmpDevelopmentCaiInitial50PctPaid)
                    ? tmpDevelopmentCaiInitial50PctPaid
                    : ""
                }
                onChange={(e) =>
                  handleUpdateEditorState(
                    "tmp_development_cai_initial_50pct_paid",
                    e.target.value
                  )
                }
              >
                {YES_NO_OPTIONS.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              error={false}
            >
              <InputLabel id="cai-final-50pct-paid">
                Common Area Irrigation Final 50% Paid?
              </InputLabel>
              <Select
                labelId="cai-final-50pct-paid-label"
                id="cai-final-50pct-paid"
                label="Common Area Irrigation Final 50% Paid?"
                value={
                  [false, true].includes(tmpDevelopmentCaiFinal50PctPaid)
                    ? tmpDevelopmentCaiFinal50PctPaid
                    : ""
                }
                onChange={(e) =>
                  handleUpdateEditorState(
                    "tmp_development_cai_final_50pct_paid",
                    e.target.value
                  )
                }
              >
                {YES_NO_OPTIONS.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Common Area Irrigation Payment Notes"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateEditorState(
                  "tmp_development_cai_payment_remark",
                  e.target.value
                )
              }
              value={tmpDevelopmentCaiPaymentRemark || ""}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default NewDevelopment;
