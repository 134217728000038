import area from "@turf/area";
import length from "@turf/length";

const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

export const getElevation = async (long, lat) => {
  // Construct the API request.
  const query = await fetch(
    `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${long},${lat}.json?layers=contour&limit=50&access_token=${mapboxToken}`,
    { method: "GET" }
  );
  if (query.status !== 200) return;
  const data = await query.json();

  const allFeatures = data.features;

  const elevations = allFeatures.map((feature) => feature.properties.ele);

  return Math.max(...elevations) * 3.28084;
};

export const handleCopyCoords = (value) => {
  const dummy = document.createElement("input");
  document.body.appendChild(dummy);
  dummy.value = value;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
};

export const DUMMY_BASEMAP_LAYERS = [
  // { url: "streets-v11", icon: "commute" },
  { url: "outdoors-v11", icon: "park" },
  { url: "satellite-streets-v11", icon: "satellite_alt" },
];

export function updateArea(
  geojson,
  type,
  polygonRef,
  radiusRef,
  pointRef,
  lineRef,
  measurementsContainerRef,
  draw,
  setMeasurementsVisible
) {
  const data = draw.getAll();
  setMeasurementsVisible(true);

  const answerArea = polygonRef.current;
  const answerRadius = radiusRef.current;
  const answerPoint = pointRef.current;
  const answerLength = lineRef.current;

  if (geojson.geometry.type === "LineString" && type !== "draw.delete") {
    const exactLengthFeet = length(geojson, { units: "feet" });
    const roundedLength = exactLengthFeet.toFixed(2);
    answerLength.innerHTML = roundedLength + " ft";
  }

  if (geojson.properties.circleRadius && type !== "draw.delete") {
    const exactRadiusKm = geojson.properties.circleRadius;
    const exactRadiusFeet = exactRadiusKm * 3280.84;
    const roundedRadius = exactRadiusFeet.toFixed(2);
    answerRadius.innerHTML = roundedRadius + " ft";
  }

  if (geojson.geometry.type === "Point" && type !== "draw.delete") {
    answerPoint.innerHTML = `<strong>lat:</strong> ${geojson.geometry.coordinates[1].toFixed(
      5
    )} <br /><strong>long:</strong> ${geojson.geometry.coordinates[0].toFixed(
      5
    )}`;
  }

  if (
    data.features.filter((item) => item.geometry.type === "Point").length === 0
  ) {
    answerPoint.innerHTML = "--";
  }
  if (
    data.features.filter((item) => item.geometry.type === "LineString")
      .length === 0
  ) {
    answerLength.innerHTML = "--";
  }
  if (
    data.features.filter((item) => item.properties.circleRadius).length === 0
  ) {
    answerRadius.innerHTML = "--";
  }

  if (data.features.length > 0) {
    const exactAreaMeters = area(data);
    const exactAreaFeet = exactAreaMeters * 10.7639;
    const exactAreaAcre = exactAreaMeters / 4047;
    const roundedAreaFeet = exactAreaFeet.toFixed(2);
    const roundedAreaAcre = exactAreaAcre.toFixed(2);
    answerArea.innerHTML =
      roundedAreaAcre + " acres or " + roundedAreaFeet + " sq ft";
  } else {
    answerArea.innerHTML = "";
    answerRadius.innerHTML = "";
    answerPoint.innerHTML = "";
    answerLength.innerHTML = "";
    setMeasurementsVisible(false);
    // if (e.type !== "draw.delete") alert("Click the map to draw a polygon.");
  }
}

export function onPointClickSetCoordinateRefs(
  coordinatesContainerRef,
  longRef,
  latRef,
  eleRef,
  featureLat,
  featureLong
) {
  coordinatesContainerRef.current.style.display = "block";
  longRef.current.innerHTML = featureLong;
  latRef.current.innerHTML = featureLat;
  (async function () {
    eleRef.current.innerHTML = await getElevation(featureLong, featureLat);
  })();
}

export const locationsLayer = {
  id: "locations",
  type: "circle",
  source: "locations",
  paint: {
    "circle-radius": 7,
    "circle-color": "#1e8dd2",
    "circle-stroke-width": 1,
    "circle-stroke-color": "black",
  },
};

export const locationsLabelsLayer = {
  id: "locations-labels",
  type: "symbol",
  source: "locations",
  // minzoom: 11.5,
  layout: {
    "text-field": ["get", "status"],
    "text-size": 14,
    "text-offset": [0, -1.7],
    "text-font": ["literal", ["Roboto Black", "Arial Unicode MS Bold"]],
    visibility: "none",
  },
  paint: {
    "text-color": "rgb(49,49,49)",
    "text-halo-color": "rgba(255,255,255,1)",
    "text-halo-width": 3,
  },
};
