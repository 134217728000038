import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { DEFAULT_ROW_STATE } from "../constants";
import useFetchData from "../../../../../hooks/useFetchData";

export const useCertificateForm = (selectedItem) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useState(DEFAULT_ROW_STATE);
  const [certificateTypes] = useFetchData("list-certificate-type", [], true);
  const resetFields = (newState, fieldsToReset) => {
    fieldsToReset.forEach((field) => (newState[field] = null));
  };
  const handleUpdateEditorState = useCallback((name, value) => {
    setEditorState((prevState) => {
      const newState = { ...prevState, [name]: value };
      const resetRules = {
        cert_holder_add_new: {
          true: ["holder_ndx"],
          false: ["tmp_cert_holder_desc", "tmp_cert_holder_remark"],
        },
        development_add_new: {
          true: ["development_ndx"],
          false: [
            "tmp_development_desc",
            "tmp_development_remark",
            "tmp_development_cai_initial_50pct_paid",
            "tmp_development_cai_final_50pct_paid",
            "tmp_development_cai_payment_remark",
          ],
        },
      };

      resetRules?.[name] && resetFields(newState, resetRules[name][value]);

      return newState;
    });
  }, []);

  const { isFetching, error } = useQuery(
    ["list-certificate", selectedItem],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-certificate/${selectedItem}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedItem,
      onSuccess: (data) => {
        setEditorState(data);
      },
    }
  );

  useEffect(() => {
    if (!selectedItem) {
      setEditorState(DEFAULT_ROW_STATE);
    }
  }, [selectedItem]);

  return {
    isLoading: isFetching,
    error,
    handleUpdateEditorState,
    editorState,
    certificateTypes,
    setEditorState,
  };
};
