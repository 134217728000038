import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

const ExistingHolderAndDevelopment = React.memo(
  ({
    certNdx,
    holderNdx,
    developmentNdx,
    certHolderAddNew,
    developmentAddNew,
    tmpCertHolderDesc,
    tmpDevelopmentDesc,
    handleUpdateEditorState,
    inputLookups,
  }) => {
    return (
      <Grid item xs={12}>
        <Grid
          container
          spacing={6}
          style={{
            border: "2px solid #808080",
            marginBottom: ".25rem",
          }}
        >
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={!holderNdx && !certHolderAddNew}
                  required={!certHolderAddNew}
                >
                  <InputLabel id="certificate-holder">
                    Certificate Holder
                  </InputLabel>
                  <Select
                    disabled={certHolderAddNew}
                    labelId="certificate-holder-label"
                    id="certificate-holder"
                    label="Certificate Holder"
                    value={holderNdx || ""}
                    onChange={(e) =>
                      handleUpdateEditorState("holder_ndx", e.target.value)
                    }
                  >
                    {inputLookups.listHolders
                      .filter((holder) => {
                        if (!certNdx) {
                          return !holder.archived;
                        } else return true;
                      })
                      .map((option) => (
                        <MenuItem
                          key={option.holder_ndx}
                          value={option.holder_ndx}
                        >
                          {option.holder_desc}
                        </MenuItem>
                      ))}
                  </Select>
                  {!holderNdx && !certHolderAddNew && (
                    <FormHelperText error>*Required field</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={!developmentNdx && !developmentAddNew}
                  required={!developmentAddNew}
                >
                  <InputLabel id="development">Development</InputLabel>
                  <Select
                    disabled={developmentAddNew}
                    labelId="development-label"
                    id="development"
                    label="Development"
                    value={developmentNdx || ""}
                    onChange={(e) =>
                      handleUpdateEditorState("development_ndx", e.target.value)
                    }
                  >
                    {inputLookups.listDevelopments
                      .filter((development) => {
                        if (!certNdx) {
                          return !development.archived;
                        } else return true;
                      })
                      .map((option) => (
                        <MenuItem
                          key={option.development_ndx}
                          value={option.development_ndx}
                        >
                          {option.development_desc}
                        </MenuItem>
                      ))}
                  </Select>
                  {!developmentNdx && !developmentAddNew && (
                    <FormHelperText error>*Required field</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {certHolderAddNew && !tmpCertHolderDesc && (
                <Grid item xs={12}>
                  <Typography
                    color="primary"
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      marginTop: "5px",
                    }}
                  >
                    ** Create New Certificate Holder Below **
                  </Typography>
                </Grid>
              )}
              {developmentAddNew && !tmpDevelopmentDesc && (
                <Grid item xs={12}>
                  <Typography
                    color="primary"
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      marginTop: "5px",
                    }}
                  >
                    ** Create New Development Below **
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ margin: "0 0 .5rem 0" }}>
            <Box flexGrow={1} mb={2}>
              <Alert severity="info" style={{ margin: "0 0 .7rem 0" }}>
                <strong>
                  Create a new Certificate Holder or a new Development - if the
                  Certificate holder or Development already exists, select them
                  in the adjacent dropdown
                </strong>
              </Alert>
            </Box>
            <Grid container justify="center" spacing={6}>
              <Grid item>
                <Button
                  disabled={!!certNdx}
                  size="large"
                  variant="contained"
                  color={certHolderAddNew ? "default" : "secondary"}
                  onClick={() =>
                    handleUpdateEditorState(
                      "cert_holder_add_new",
                      !certHolderAddNew
                    )
                  }
                >
                  Create a Certificate Holder
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!!certNdx}
                  size="large"
                  variant="contained"
                  color={developmentAddNew ? "default" : "secondary"}
                  onClick={() =>
                    handleUpdateEditorState(
                      "development_add_new",
                      !developmentAddNew
                    )
                  }
                >
                  Create a Development
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default ExistingHolderAndDevelopment;
