import React from "react";
import { useQuery } from "react-query";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";

import styled from "styled-components/macro";

import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";

import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const PopupUl = styled.ul`
  //list-style-type: none;
  margin: 0 !important;
  padding: 3px 0;
`;

//388px
const CertificatesOverview = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isFetching, error } = useQuery(
    ["report-certificate-details"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/report-certificate-details`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (error) return "An error has occurred: " + error.message;

  const tabColumns = [
    {
      title: "Certificate No.",
      field: "certificate_no",
    },
    {
      title: "Certificate Holder",
      field: "holder_desc",
    },
    {
      title: "Development",
      field: "development_desc",
    },
    {
      title: "Active?",
      field: "active",
      render: (rowData) =>
        rowData.active ? "Yes" : rowData.active === false ? "No" : "",
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "Remaining (AF)",
      field: "credits_remaining_af",
    },
    {
      title: "% Remaining",
      field: "percent_remaining",
    },
    {
      title: "Holder Has Copy?",
      field: "holder_has_copy",
      render: (rowData) =>
        rowData.holder_has_copy
          ? "Yes"
          : rowData.holder_has_copy === false
          ? "No"
          : "",
    },
    {
      title: "Related Certificate Documents",
      field: "documents_certificates",
      render: (rowData) => {
        return (
          <PopupUl>
            {rowData?.documents_certificates?.split(",").map((item) => (
              <li key={item}>{parse(item)}</li>
            ))}
          </PopupUl>
        );
      },
    },
    {
      title: "Related Development Documents",
      field: "documents_developments",
      render: (rowData) => {
        return (
          <PopupUl>
            {rowData?.documents_developments?.split(",").map((item) => (
              <li key={item}>{parse(item)}</li>
            ))}
          </PopupUl>
        );
      },
    },
    {
      title: "Low Balance Alert",
      field: "low_balance_alert",
    },
  ];

  return (
    <>
      <Helmet title="Certificates Overview" />
      <Typography variant="h3" gutterBottom display="inline">
        Certificates Overview Report
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Certificates Overview</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Panel>
        <TableWrapper>
          <Table
            pageSize={200}
            options={{
              filtering: false,
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.low_balance_alert === "Yes" ? "#ffdee2" : "#FFF",
              }),
            }}
            label="Certificates Overview"
            isLoading={isFetching}
            columns={tabColumns}
            data={data}
            height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
          />
        </TableWrapper>
      </Panel>
    </>
  );
};

export default CertificatesOverview;
