import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { YES_NO_OPTIONS } from "../constants";
import React from "react";
import DatePicker from "../../../../../components/pickers/DatePicker";
import { isValid } from "date-fns";

const CertificateDetails = React.memo(
  ({
    certificateNdx,
    certificateNo,
    holderHasCopy,
    archived,
    certificateDate,
    remark,
    certificateTypeNdx,
    pudNotes,
    assocCertificateNo,
    archivedNotes,
    paymentFileName,
    paymentRemark,
    certificateTypes,
    certHolderAddNew,
    developmentAddNew,
    handleUpdateEditorState,
  }) => {
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {(certHolderAddNew || developmentAddNew) && (
            <Grid
              item
              xs={12}
              style={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Certificate
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  error={!certificateNo}
                  helperText={!certificateNo ? "*Required field" : ""}
                  variant="outlined"
                  label="Certificate No."
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleUpdateEditorState("certificate_no", e.target.value)
                  }
                  value={certificateNo || ""}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="certificate-holder">
                    Holder Has Copy?
                  </InputLabel>
                  <Select
                    labelId="holder-has-copy-label"
                    id="holderHasCopy"
                    label="Holder Has Copy?"
                    value={
                      typeof holderHasCopy === "boolean" ? holderHasCopy : ""
                    }
                    onChange={(e) =>
                      handleUpdateEditorState("holder_has_copy", e.target.value)
                    }
                  >
                    {YES_NO_OPTIONS.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl
                  required={false}
                  variant="outlined"
                  error={typeof archived !== "boolean" && !certificateNdx}
                  style={{ width: "100%" }}
                >
                  <InputLabel id="archived">Archived?</InputLabel>
                  <Select
                    required={false}
                    labelId="archived-label"
                    id="archived"
                    label="Archived?"
                    value={typeof archived === "boolean" ? archived : ""}
                    onChange={(e) =>
                      handleUpdateEditorState("archived", e.target.value)
                    }
                  >
                    {YES_NO_OPTIONS.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <DatePicker
                label="Certificate Date"
                name="certificate_date"
                selectedDate={certificateDate}
                setSelectedDate={handleUpdateEditorState}
                required={false}
                error={certificateDate && !isValid(new Date(certificateDate))}
              />
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              label="General Notes"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateEditorState("remark", e.target.value)
              }
              value={remark || ""}
            />
          </Grid>
          <Grid item xs={3}>
            {certificateTypes.length > 0 && (
              <FormControl
                variant="outlined"
                required
                style={{ width: "100%" }}
                error={!certificateTypeNdx}
              >
                <InputLabel id="certificate-type-tag">
                  Certificate Type
                </InputLabel>
                <Select
                  labelId="certificate-type-tag-label"
                  disabled={false}
                  id="certificate-type-tag"
                  label="CertificateType"
                  value={certificateTypeNdx || ""}
                  onChange={(e) => {
                    handleUpdateEditorState(
                      "certificate_type_ndx",
                      e.target.value
                    );
                  }}
                >
                  {certificateTypes.map((certificate_type) => (
                    <MenuItem
                      key={certificate_type.certificate_type_ndx}
                      value={certificate_type.certificate_type_ndx}
                    >
                      {certificate_type.desc_for_data_entry}
                    </MenuItem>
                  ))}
                </Select>
                {!certificateTypeNdx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            )}
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              label="PUD Notes"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateEditorState("pud_notes", e.target.value)
              }
              value={pudNotes || ""}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              label="Assoc Cert"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateEditorState("assoc_certificate_no", e.target.value)
              }
              value={assocCertificateNo || ""}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              label="Archive Notes"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateEditorState("archived_notes", e.target.value)
              }
              value={archivedNotes || ""}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              label="Payment File"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateEditorState("payment_file_name", e.target.value)
              }
              value={paymentFileName || ""}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              label="Payment Remark"
              style={{ width: "100%" }}
              onChange={(e) =>
                handleUpdateEditorState("payment_remark", e.target.value)
              }
              value={paymentRemark || ""}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default CertificateDetails;
